import { inject } from '@angular/core';
import { PrerenderFallback, RenderMode, ServerRoute } from '@angular/ssr';
import { ArticleService } from './services/articles/article.service';
import { take } from 'rxjs';

export const serverRoutes: ServerRoute[] = [
  {
    path: '',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'pricecheck',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'pricecheck/:uniqueName',
    renderMode: RenderMode.Prerender,
    // fallback: PrerenderFallback.Client,
    async getPrerenderParams() {
      return priceCheckUniqueNames.map((uniqueName) => ({ uniqueName }));
    },
  },
  {
    path: 'pricefavorites',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'flipper',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'yourflips',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'crafts',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'repaircost',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'journals',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'itempower',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'articles',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'articles/view/:id',
    renderMode: RenderMode.Prerender,
    // fallback: PrerenderFallback.Client,
    async getPrerenderParams() {
      return articles.map((article) => ({ id: article }));
    },
  },
  {
    path: 'metaitems',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'topitems',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'traderoutes',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'crafting',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'farming',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'transport',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'faq',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'changelog',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'feedback',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'privacy-policy',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'support-us',
    renderMode: RenderMode.Prerender,
  },
  {
    path: '**',
    renderMode: RenderMode.Client,
  },
];

const articles = [
  'albion-online-solo-weapons-analysis---europe',
  'the-albion-online-data-project-client-tutorial',
  'mastering-the-art-of-farming-in-albion-online',
  'albion-online-black-market-flipper-tutorial',
  'understanding-item-power-in-albion-online',
];

export const priceCheckUniqueNames = [
  'T3_2H_TOOL_TRACKING',
  'T4_2H_TOOL_TRACKING',
  'T5_2H_TOOL_TRACKING',
  'T6_2H_TOOL_TRACKING',
  'T7_2H_TOOL_TRACKING',
  'T8_2H_TOOL_TRACKING',
  'T1_FARM_CARROT_SEED',
  'T2_FARM_BEAN_SEED',
  'T3_FARM_WHEAT_SEED',
  'T4_FARM_TURNIP_SEED',
  'T5_FARM_CABBAGE_SEED',
  'T6_FARM_POTATO_SEED',
  'T7_FARM_CORN_SEED',
  'T8_FARM_PUMPKIN_SEED',
  'T2_FARM_AGARIC_SEED',
  'T3_FARM_COMFREY_SEED',
  'T4_FARM_BURDOCK_SEED',
  'T5_FARM_TEASEL_SEED',
  'T6_FARM_FOXGLOVE_SEED',
  'T7_FARM_MULLEIN_SEED',
  'T8_FARM_YARROW_SEED',
  'T3_FARM_OX_BABY',
  'T4_FARM_OX_BABY',
  'T5_FARM_OX_BABY',
  'T6_FARM_OX_BABY',
  'T7_FARM_OX_BABY',
  'T8_FARM_OX_BABY',
  'T3_FARM_OX_GROWN',
  'T4_FARM_OX_GROWN',
  'T5_FARM_OX_GROWN',
  'T6_FARM_OX_GROWN',
  'T7_FARM_OX_GROWN',
  'T8_FARM_OX_GROWN',
  'T3_FARM_HORSE_BABY',
  'T4_FARM_HORSE_BABY',
  'T5_FARM_HORSE_BABY',
  'T6_FARM_HORSE_BABY',
  'T7_FARM_HORSE_BABY',
  'T8_FARM_HORSE_BABY',
  'T3_FARM_HORSE_GROWN',
  'T4_FARM_HORSE_GROWN',
  'T5_FARM_HORSE_GROWN',
  'T6_FARM_HORSE_GROWN',
  'T7_FARM_HORSE_GROWN',
  'T8_FARM_HORSE_GROWN',
  'T6_FARM_DIREWOLF_BABY',
  'T7_FARM_DIREBOAR_BABY',
  'T8_FARM_DIREBEAR_BABY',
  'T7_FARM_SWAMPDRAGON_BABY',
  'T8_FARM_MAMMOTH_BABY',
  'T5_FARM_COUGAR_BABY',
  'T8_FARM_DIREWOLF_BABY',
  'T4_FARM_GIANTSTAG_BABY',
  'T6_FARM_GIANTSTAG_BABY',
  'T6_FARM_GIANTSTAG_MOOSE_BABY',
  'T8_FARM_RABBIT_EASTER_BABY',
  'T8_FARM_RABBIT_EASTER_BABY_DARK',
  'T8_FARM_RABBIT_EASTER_GROWN',
  'T8_FARM_RABBIT_EASTER_GROWN_DARK',
  'T5_FARM_MOABIRD_FW_BRIDGEWATCH_BABY',
  'T5_FARM_DIREBEAR_FW_FORTSTERLING_BABY',
  'T5_FARM_DIREBOAR_FW_LYMHURST_BABY',
  'T5_FARM_RAM_FW_MARTLOCK_BABY',
  'T5_FARM_SWAMPDRAGON_FW_THETFORD_BABY',
  'T5_FARM_GREYWOLF_FW_CAERLEON_BABY',
  'T5_FARM_OWL_FW_BRECILIEN_BABY',
  'T8_FARM_MOABIRD_FW_BRIDGEWATCH_BABY',
  'T8_FARM_DIREBEAR_FW_FORTSTERLING_BABY',
  'T8_FARM_DIREBOAR_FW_LYMHURST_BABY',
  'T8_FARM_RAM_FW_MARTLOCK_BABY',
  'T8_FARM_SWAMPDRAGON_FW_THETFORD_BABY',
  'T8_FARM_GREYWOLF_FW_CAERLEON_BABY',
  'T8_FARM_OWL_FW_BRECILIEN_BABY',
  'T6_FARM_DIREWOLF_GROWN',
  'T7_FARM_DIREBOAR_GROWN',
  'T8_FARM_DIREBEAR_GROWN',
  'T7_FARM_SWAMPDRAGON_GROWN',
  'T8_FARM_MAMMOTH_GROWN',
  'T5_FARM_COUGAR_GROWN',
  'T8_FARM_DIREWOLF_GROWN',
  'T4_FARM_GIANTSTAG_GROWN',
  'T6_FARM_GIANTSTAG_GROWN',
  'T6_FARM_GIANTSTAG_MOOSE_GROWN',
  'T5_FARM_MOABIRD_FW_BRIDGEWATCH_GROWN',
  'T5_FARM_DIREBEAR_FW_FORTSTERLING_GROWN',
  'T5_FARM_DIREBOAR_FW_LYMHURST_GROWN',
  'T5_FARM_RAM_FW_MARTLOCK_GROWN',
  'T5_FARM_SWAMPDRAGON_FW_THETFORD_GROWN',
  'T5_FARM_GREYWOLF_FW_CAERLEON_GROWN',
  'T5_FARM_OWL_FW_BRECILIEN_GROWN',
  'T8_FARM_MOABIRD_FW_BRIDGEWATCH_GROWN',
  'T8_FARM_DIREBEAR_FW_FORTSTERLING_GROWN',
  'T8_FARM_DIREBOAR_FW_LYMHURST_GROWN',
  'T8_FARM_RAM_FW_MARTLOCK_GROWN',
  'T8_FARM_SWAMPDRAGON_FW_THETFORD_GROWN',
  'T8_FARM_GREYWOLF_FW_CAERLEON_GROWN',
  'T8_FARM_OWL_FW_BRECILIEN_GROWN',
  'T3_FARM_CHICKEN_BABY',
  'T4_FARM_GOAT_BABY',
  'T5_FARM_GOOSE_BABY',
  'T6_FARM_SHEEP_BABY',
  'T7_FARM_PIG_BABY',
  'T8_FARM_COW_BABY',
  'T3_FARM_CHICKEN_GROWN',
  'T4_FARM_GOAT_GROWN',
  'T5_FARM_GOOSE_GROWN',
  'T6_FARM_SHEEP_GROWN',
  'T7_FARM_PIG_GROWN',
  'T8_FARM_COW_GROWN',
  'T6_MOUNTUPGRADE_GIANTSTAG_XMAS',
  'UNIQUE_MOUNTUPGRADE_RAM_XMAS',
  'T5_MOUNTUPGRADE_HORSE_CURSE',
  'T8_MOUNTUPGRADE_HORSE_CURSE',
  'T8_MOUNTUPGRADE_COUGAR_KEEPER',
  'T5_MOUNTUPGRADE_HORSE_MORGANA',
  'T8_MOUNTUPGRADE_HORSE_MORGANA',
  'T1_CARROT',
  'T2_BEAN',
  'T3_WHEAT',
  'T4_TURNIP',
  'T5_CABBAGE',
  'T6_POTATO',
  'T7_CORN',
  'T8_PUMPKIN',
  'T2_AGARIC',
  'T3_COMFREY',
  'T4_BURDOCK',
  'T5_TEASEL',
  'T6_FOXGLOVE',
  'T7_MULLEIN',
  'T8_YARROW',
  'T3_EGG',
  'T4_MILK',
  'T5_EGG',
  'T6_MILK',
  'T8_MILK',
  'T1_SEAWEED',
  'T8_FISH_SALTWATER_ALL_BOSS_SHARK',
  'T1_FISHCHOPS',
  'T1_FISHSAUCE_LEVEL1',
  'T1_FISHSAUCE_LEVEL2',
  'T1_FISHSAUCE_LEVEL3',
  'TREASURE_KNOWLEDGE_RARITY1',
  'TREASURE_KNOWLEDGE_RARITY2',
  'TREASURE_KNOWLEDGE_RARITY3',
  'TREASURE_SILVERWARE_RARITY1',
  'TREASURE_SILVERWARE_RARITY2',
  'TREASURE_SILVERWARE_RARITY3',
  'TREASURE_DECORATIVE_RARITY1',
  'TREASURE_DECORATIVE_RARITY2',
  'TREASURE_DECORATIVE_RARITY3',
  'TREASURE_CEREMONIAL_RARITY1',
  'TREASURE_CEREMONIAL_RARITY2',
  'TREASURE_CEREMONIAL_RARITY3',
  'TREASURE_TRIBAL_RARITY1',
  'TREASURE_TRIBAL_RARITY2',
  'TREASURE_TRIBAL_RARITY3',
  'TREASURE_RITUAL_RARITY1',
  'TREASURE_RITUAL_RARITY2',
  'TREASURE_RITUAL_RARITY3',
  'TREASURE_AVALON_RARITY1',
  'TREASURE_AVALON_RARITY2',
  'TREASURE_AVALON_RARITY3',
  'QUESTITEM_TOKEN_AVALON',
  'QUESTITEM_TOKEN_MISTS',
  'T4_SKILLBOOK_GATHER_FIBER',
  'T5_SKILLBOOK_GATHER_FIBER',
  'T6_SKILLBOOK_GATHER_FIBER',
  'T7_SKILLBOOK_GATHER_FIBER',
  'T8_SKILLBOOK_GATHER_FIBER',
  'T4_SKILLBOOK_GATHER_FIBER_NONTRADABLE',
  'T5_SKILLBOOK_GATHER_FIBER_NONTRADABLE',
  'T6_SKILLBOOK_GATHER_FIBER_NONTRADABLE',
  'T7_SKILLBOOK_GATHER_FIBER_NONTRADABLE',
  'T8_SKILLBOOK_GATHER_FIBER_NONTRADABLE',
  'T4_SKILLBOOK_GATHER_HIDE',
  'T5_SKILLBOOK_GATHER_HIDE',
  'T6_SKILLBOOK_GATHER_HIDE',
  'T7_SKILLBOOK_GATHER_HIDE',
  'T8_SKILLBOOK_GATHER_HIDE',
  'T4_SKILLBOOK_GATHER_HIDE_NONTRADABLE',
  'T5_SKILLBOOK_GATHER_HIDE_NONTRADABLE',
  'T6_SKILLBOOK_GATHER_HIDE_NONTRADABLE',
  'T7_SKILLBOOK_GATHER_HIDE_NONTRADABLE',
  'T8_SKILLBOOK_GATHER_HIDE_NONTRADABLE',
  'T4_SKILLBOOK_GATHER_ORE',
  'T5_SKILLBOOK_GATHER_ORE',
  'T6_SKILLBOOK_GATHER_ORE',
  'T7_SKILLBOOK_GATHER_ORE',
  'T8_SKILLBOOK_GATHER_ORE',
  'T4_SKILLBOOK_GATHER_ORE_NONTRADABLE',
  'T5_SKILLBOOK_GATHER_ORE_NONTRADABLE',
  'T6_SKILLBOOK_GATHER_ORE_NONTRADABLE',
  'T7_SKILLBOOK_GATHER_ORE_NONTRADABLE',
  'T8_SKILLBOOK_GATHER_ORE_NONTRADABLE',
  'T4_SKILLBOOK_GATHER_ROCK',
  'T5_SKILLBOOK_GATHER_ROCK',
  'T6_SKILLBOOK_GATHER_ROCK',
  'T7_SKILLBOOK_GATHER_ROCK',
  'T8_SKILLBOOK_GATHER_ROCK',
  'T4_SKILLBOOK_GATHER_ROCK_NONTRADABLE',
  'T5_SKILLBOOK_GATHER_ROCK_NONTRADABLE',
  'T6_SKILLBOOK_GATHER_ROCK_NONTRADABLE',
  'T7_SKILLBOOK_GATHER_ROCK_NONTRADABLE',
  'T8_SKILLBOOK_GATHER_ROCK_NONTRADABLE',
  'T4_SKILLBOOK_GATHER_WOOD',
  'T5_SKILLBOOK_GATHER_WOOD',
  'T6_SKILLBOOK_GATHER_WOOD',
  'T7_SKILLBOOK_GATHER_WOOD',
  'T8_SKILLBOOK_GATHER_WOOD',
  'T4_SKILLBOOK_GATHER_WOOD_NONTRADABLE',
  'T5_SKILLBOOK_GATHER_WOOD_NONTRADABLE',
  'T6_SKILLBOOK_GATHER_WOOD_NONTRADABLE',
  'T7_SKILLBOOK_GATHER_WOOD_NONTRADABLE',
  'T8_SKILLBOOK_GATHER_WOOD_NONTRADABLE',
  'T4_SKILLBOOK_STANDARD',
  'T1_SKILLBOOK_NONTRADABLE',
  'T2_SKILLBOOK_NONTRADABLE',
  'T3_SKILLBOOK_NONTRADABLE',
  'T4_SKILLBOOK_NONTRADABLE',
  'T5_SKILLBOOK_NONTRADABLE',
  'T6_SKILLBOOK_NONTRADABLE',
  'T7_SKILLBOOK_NONTRADABLE',
  'T8_SKILLBOOK_NONTRADABLE',
  'T1_LEARNINGPOINTS_NONTRADABLE',
  'T2_LEARNINGPOINTS_NONTRADABLE',
  'T3_LEARNINGPOINTS_NONTRADABLE',
  'T4_LEARNINGPOINTS_NONTRADABLE',
  'T5_LEARNINGPOINTS_NONTRADABLE',
  'T6_LEARNINGPOINTS_NONTRADABLE',
  'T7_LEARNINGPOINTS_NONTRADABLE',
  'T8_LEARNINGPOINTS_NONTRADABLE',
  'T3_PREMIUMITEM_3_NONTRADABLE',
  'T1_SILVERBAG_NONTRADABLE',
  'T2_SILVERBAG_NONTRADABLE',
  'T3_SILVERBAG_NONTRADABLE',
  'T4_SILVERBAG_NONTRADABLE',
  'T5_SILVERBAG_NONTRADABLE',
  'T6_SILVERBAG_NONTRADABLE',
  'T7_SILVERBAG_NONTRADABLE',
  'T8_SILVERBAG_NONTRADABLE',
  'T1_WORM',
  'T3_MEAT',
  'T4_MEAT',
  'T5_MEAT',
  'T6_MEAT',
  'T7_MEAT',
  'T8_MEAT',
  'T4_BUTTER',
  'T6_BUTTER',
  'T8_BUTTER',
  'T6_ALCOHOL',
  'T7_ALCOHOL',
  'T8_ALCOHOL',
  'T4_BREAD',
  'T3_FLOUR',
  'T1_WOOD',
  'T2_WOOD',
  'T3_WOOD',
  'T4_WOOD',
  'T5_WOOD',
  'T6_WOOD',
  'T7_WOOD',
  'T8_WOOD',
  'T4_WOOD_LEVEL1@1',
  'T5_WOOD_LEVEL1@1',
  'T6_WOOD_LEVEL1@1',
  'T7_WOOD_LEVEL1@1',
  'T8_WOOD_LEVEL1@1',
  'T4_WOOD_LEVEL2@2',
  'T5_WOOD_LEVEL2@2',
  'T6_WOOD_LEVEL2@2',
  'T7_WOOD_LEVEL2@2',
  'T8_WOOD_LEVEL2@2',
  'T4_WOOD_LEVEL3@3',
  'T5_WOOD_LEVEL3@3',
  'T6_WOOD_LEVEL3@3',
  'T7_WOOD_LEVEL3@3',
  'T8_WOOD_LEVEL3@3',
  'T4_WOOD_LEVEL4@4',
  'T5_WOOD_LEVEL4@4',
  'T6_WOOD_LEVEL4@4',
  'T7_WOOD_LEVEL4@4',
  'T8_WOOD_LEVEL4@4',
  'T1_ROCK',
  'T2_ROCK',
  'T3_ROCK',
  'T4_ROCK',
  'T5_ROCK',
  'T6_ROCK',
  'T7_ROCK',
  'T8_ROCK',
  'T4_ROCK_LEVEL1@1',
  'T5_ROCK_LEVEL1@1',
  'T6_ROCK_LEVEL1@1',
  'T7_ROCK_LEVEL1@1',
  'T8_ROCK_LEVEL1@1',
  'T4_ROCK_LEVEL2@2',
  'T5_ROCK_LEVEL2@2',
  'T6_ROCK_LEVEL2@2',
  'T7_ROCK_LEVEL2@2',
  'T8_ROCK_LEVEL2@2',
  'T4_ROCK_LEVEL3@3',
  'T5_ROCK_LEVEL3@3',
  'T6_ROCK_LEVEL3@3',
  'T7_ROCK_LEVEL3@3',
  'T8_ROCK_LEVEL3@3',
  'T2_ORE',
  'T3_ORE',
  'T4_ORE',
  'T5_ORE',
  'T6_ORE',
  'T7_ORE',
  'T8_ORE',
  'T4_ORE_LEVEL1@1',
  'T5_ORE_LEVEL1@1',
  'T6_ORE_LEVEL1@1',
  'T7_ORE_LEVEL1@1',
  'T8_ORE_LEVEL1@1',
  'T4_ORE_LEVEL2@2',
  'T5_ORE_LEVEL2@2',
  'T6_ORE_LEVEL2@2',
  'T7_ORE_LEVEL2@2',
  'T8_ORE_LEVEL2@2',
  'T4_ORE_LEVEL3@3',
  'T5_ORE_LEVEL3@3',
  'T6_ORE_LEVEL3@3',
  'T7_ORE_LEVEL3@3',
  'T8_ORE_LEVEL3@3',
  'T4_ORE_LEVEL4@4',
  'T5_ORE_LEVEL4@4',
  'T6_ORE_LEVEL4@4',
  'T7_ORE_LEVEL4@4',
  'T8_ORE_LEVEL4@4',
  'T1_HIDE',
  'T2_HIDE',
  'T3_HIDE',
  'T4_HIDE',
  'T5_HIDE',
  'T6_HIDE',
  'T7_HIDE',
  'T8_HIDE',
  'T4_HIDE_LEVEL1@1',
  'T5_HIDE_LEVEL1@1',
  'T6_HIDE_LEVEL1@1',
  'T7_HIDE_LEVEL1@1',
  'T8_HIDE_LEVEL1@1',
  'T4_HIDE_LEVEL2@2',
  'T5_HIDE_LEVEL2@2',
  'T6_HIDE_LEVEL2@2',
  'T7_HIDE_LEVEL2@2',
  'T8_HIDE_LEVEL2@2',
  'T4_HIDE_LEVEL3@3',
  'T5_HIDE_LEVEL3@3',
  'T6_HIDE_LEVEL3@3',
  'T7_HIDE_LEVEL3@3',
  'T8_HIDE_LEVEL3@3',
  'T4_HIDE_LEVEL4@4',
  'T5_HIDE_LEVEL4@4',
  'T6_HIDE_LEVEL4@4',
  'T7_HIDE_LEVEL4@4',
  'T8_HIDE_LEVEL4@4',
  'T2_FIBER',
  'T3_FIBER',
  'T4_FIBER',
  'T5_FIBER',
  'T6_FIBER',
  'T7_FIBER',
  'T8_FIBER',
  'T4_FIBER_LEVEL1@1',
  'T5_FIBER_LEVEL1@1',
  'T6_FIBER_LEVEL1@1',
  'T7_FIBER_LEVEL1@1',
  'T8_FIBER_LEVEL1@1',
  'T4_FIBER_LEVEL2@2',
  'T5_FIBER_LEVEL2@2',
  'T6_FIBER_LEVEL2@2',
  'T7_FIBER_LEVEL2@2',
  'T8_FIBER_LEVEL2@2',
  'T4_FIBER_LEVEL3@3',
  'T5_FIBER_LEVEL3@3',
  'T6_FIBER_LEVEL3@3',
  'T7_FIBER_LEVEL3@3',
  'T8_FIBER_LEVEL3@3',
  'T4_FIBER_LEVEL4@4',
  'T5_FIBER_LEVEL4@4',
  'T6_FIBER_LEVEL4@4',
  'T7_FIBER_LEVEL4@4',
  'T8_FIBER_LEVEL4@4',
  'T2_PLANKS',
  'T3_PLANKS',
  'T4_PLANKS',
  'T5_PLANKS',
  'T6_PLANKS',
  'T7_PLANKS',
  'T8_PLANKS',
  'T4_PLANKS_LEVEL1@1',
  'T5_PLANKS_LEVEL1@1',
  'T6_PLANKS_LEVEL1@1',
  'T7_PLANKS_LEVEL1@1',
  'T8_PLANKS_LEVEL1@1',
  'T4_PLANKS_LEVEL2@2',
  'T5_PLANKS_LEVEL2@2',
  'T6_PLANKS_LEVEL2@2',
  'T7_PLANKS_LEVEL2@2',
  'T8_PLANKS_LEVEL2@2',
  'T4_PLANKS_LEVEL3@3',
  'T5_PLANKS_LEVEL3@3',
  'T6_PLANKS_LEVEL3@3',
  'T7_PLANKS_LEVEL3@3',
  'T8_PLANKS_LEVEL3@3',
  'T4_PLANKS_LEVEL4@4',
  'T5_PLANKS_LEVEL4@4',
  'T6_PLANKS_LEVEL4@4',
  'T7_PLANKS_LEVEL4@4',
  'T8_PLANKS_LEVEL4@4',
  'T2_STONEBLOCK',
  'T3_STONEBLOCK',
  'T4_STONEBLOCK',
  'T5_STONEBLOCK',
  'T6_STONEBLOCK',
  'T7_STONEBLOCK',
  'T8_STONEBLOCK',
  'T2_METALBAR',
  'T3_METALBAR',
  'T4_METALBAR',
  'T5_METALBAR',
  'T6_METALBAR',
  'T7_METALBAR',
  'T8_METALBAR',
  'T4_METALBAR_LEVEL1@1',
  'T5_METALBAR_LEVEL1@1',
  'T6_METALBAR_LEVEL1@1',
  'T7_METALBAR_LEVEL1@1',
  'T8_METALBAR_LEVEL1@1',
  'T4_METALBAR_LEVEL2@2',
  'T5_METALBAR_LEVEL2@2',
  'T6_METALBAR_LEVEL2@2',
  'T7_METALBAR_LEVEL2@2',
  'T8_METALBAR_LEVEL2@2',
  'T4_METALBAR_LEVEL3@3',
  'T5_METALBAR_LEVEL3@3',
  'T6_METALBAR_LEVEL3@3',
  'T7_METALBAR_LEVEL3@3',
  'T8_METALBAR_LEVEL3@3',
  'T4_METALBAR_LEVEL4@4',
  'T5_METALBAR_LEVEL4@4',
  'T6_METALBAR_LEVEL4@4',
  'T7_METALBAR_LEVEL4@4',
  'T8_METALBAR_LEVEL4@4',
  'T2_LEATHER',
  'T3_LEATHER',
  'T4_LEATHER',
  'T5_LEATHER',
  'T6_LEATHER',
  'T7_LEATHER',
  'T8_LEATHER',
  'T4_LEATHER_LEVEL1@1',
  'T5_LEATHER_LEVEL1@1',
  'T6_LEATHER_LEVEL1@1',
  'T7_LEATHER_LEVEL1@1',
  'T8_LEATHER_LEVEL1@1',
  'T4_LEATHER_LEVEL2@2',
  'T5_LEATHER_LEVEL2@2',
  'T6_LEATHER_LEVEL2@2',
  'T7_LEATHER_LEVEL2@2',
  'T8_LEATHER_LEVEL2@2',
  'T4_LEATHER_LEVEL3@3',
  'T5_LEATHER_LEVEL3@3',
  'T6_LEATHER_LEVEL3@3',
  'T7_LEATHER_LEVEL3@3',
  'T8_LEATHER_LEVEL3@3',
  'T4_LEATHER_LEVEL4@4',
  'T5_LEATHER_LEVEL4@4',
  'T6_LEATHER_LEVEL4@4',
  'T7_LEATHER_LEVEL4@4',
  'T8_LEATHER_LEVEL4@4',
  'T2_CLOTH',
  'T3_CLOTH',
  'T4_CLOTH',
  'T5_CLOTH',
  'T6_CLOTH',
  'T7_CLOTH',
  'T8_CLOTH',
  'T4_CLOTH_LEVEL1@1',
  'T5_CLOTH_LEVEL1@1',
  'T6_CLOTH_LEVEL1@1',
  'T7_CLOTH_LEVEL1@1',
  'T8_CLOTH_LEVEL1@1',
  'T4_CLOTH_LEVEL2@2',
  'T5_CLOTH_LEVEL2@2',
  'T6_CLOTH_LEVEL2@2',
  'T7_CLOTH_LEVEL2@2',
  'T8_CLOTH_LEVEL2@2',
  'T4_CLOTH_LEVEL3@3',
  'T5_CLOTH_LEVEL3@3',
  'T6_CLOTH_LEVEL3@3',
  'T7_CLOTH_LEVEL3@3',
  'T8_CLOTH_LEVEL3@3',
  'T4_CLOTH_LEVEL4@4',
  'T5_CLOTH_LEVEL4@4',
  'T6_CLOTH_LEVEL4@4',
  'T7_CLOTH_LEVEL4@4',
  'T8_CLOTH_LEVEL4@4',
  'T3_ALCHEMY_RARE_PANTHER',
  'T5_ALCHEMY_RARE_PANTHER',
  'T7_ALCHEMY_RARE_PANTHER',
  'T3_ALCHEMY_RARE_ENT',
  'T5_ALCHEMY_RARE_ENT',
  'T7_ALCHEMY_RARE_ENT',
  'T3_ALCHEMY_RARE_DIREBEAR',
  'T5_ALCHEMY_RARE_DIREBEAR',
  'T7_ALCHEMY_RARE_DIREBEAR',
  'T3_ALCHEMY_RARE_WEREWOLF',
  'T5_ALCHEMY_RARE_WEREWOLF',
  'T7_ALCHEMY_RARE_WEREWOLF',
  'T3_ALCHEMY_RARE_IMP',
  'T5_ALCHEMY_RARE_IMP',
  'T7_ALCHEMY_RARE_IMP',
  'T3_ALCHEMY_RARE_ELEMENTAL',
  'T5_ALCHEMY_RARE_ELEMENTAL',
  'T7_ALCHEMY_RARE_ELEMENTAL',
  'T3_ALCHEMY_RARE_EAGLE',
  'T5_ALCHEMY_RARE_EAGLE',
  'T7_ALCHEMY_RARE_EAGLE',
  'T1_ALCHEMY_COMMON',
  'T1_ALCHEMY_EXTRACT_LEVEL1',
  'T1_ALCHEMY_EXTRACT_LEVEL2',
  'T1_ALCHEMY_EXTRACT_LEVEL3',
  'T4_ESSENCE_POTION',
  'T4_ESSENCE',
  'T4_RUNE',
  'T4_SOUL',
  'T4_RELIC',
  'T4_SHARD_AVALONIAN',
  'T4_SHARD_CRYSTAL',
  'T5_ESSENCE_POTION',
  'T5_ESSENCE',
  'T5_RUNE',
  'T5_SOUL',
  'T5_RELIC',
  'T5_SHARD_AVALONIAN',
  'T5_SHARD_CRYSTAL',
  'T6_ESSENCE_POTION',
  'T6_ESSENCE',
  'T6_RUNE',
  'T6_SOUL',
  'T6_RELIC',
  'T6_SHARD_AVALONIAN',
  'T6_SHARD_CRYSTAL',
  'T7_ESSENCE_POTION',
  'T7_ESSENCE',
  'T7_RUNE',
  'T7_SOUL',
  'T7_RELIC',
  'T7_SHARD_AVALONIAN',
  'T7_SHARD_CRYSTAL',
  'T8_ESSENCE_POTION',
  'T8_ESSENCE',
  'T8_RUNE',
  'T8_SOUL',
  'T8_RELIC',
  'T8_SHARD_AVALONIAN',
  'T8_SHARD_CRYSTAL',
  'T1_OFF_SHIELD',
  'T2_OFF_SHIELD',
  'T3_OFF_SHIELD',
  'T4_OFF_SHIELD',
  'T5_OFF_SHIELD',
  'T6_OFF_SHIELD',
  'T7_OFF_SHIELD',
  'T8_OFF_SHIELD',
  'T4_OFF_TOWERSHIELD_UNDEAD',
  'T5_OFF_TOWERSHIELD_UNDEAD',
  'T6_OFF_TOWERSHIELD_UNDEAD',
  'T7_OFF_TOWERSHIELD_UNDEAD',
  'T8_OFF_TOWERSHIELD_UNDEAD',
  'T4_OFF_SHIELD_HELL',
  'T5_OFF_SHIELD_HELL',
  'T6_OFF_SHIELD_HELL',
  'T7_OFF_SHIELD_HELL',
  'T8_OFF_SHIELD_HELL',
  'T4_OFF_SPIKEDSHIELD_MORGANA',
  'T5_OFF_SPIKEDSHIELD_MORGANA',
  'T6_OFF_SPIKEDSHIELD_MORGANA',
  'T7_OFF_SPIKEDSHIELD_MORGANA',
  'T8_OFF_SPIKEDSHIELD_MORGANA',
  'T4_OFF_SHIELD_AVALON',
  'T5_OFF_SHIELD_AVALON',
  'T6_OFF_SHIELD_AVALON',
  'T7_OFF_SHIELD_AVALON',
  'T8_OFF_SHIELD_AVALON',
  'T2_OFF_BOOK',
  'T3_OFF_BOOK',
  'T4_OFF_BOOK',
  'T5_OFF_BOOK',
  'T6_OFF_BOOK',
  'T7_OFF_BOOK',
  'T8_OFF_BOOK',
  'T4_OFF_ORB_MORGANA',
  'T5_OFF_ORB_MORGANA',
  'T6_OFF_ORB_MORGANA',
  'T7_OFF_ORB_MORGANA',
  'T8_OFF_ORB_MORGANA',
  'T4_OFF_DEMONSKULL_HELL',
  'T5_OFF_DEMONSKULL_HELL',
  'T6_OFF_DEMONSKULL_HELL',
  'T7_OFF_DEMONSKULL_HELL',
  'T8_OFF_DEMONSKULL_HELL',
  'T4_OFF_TOTEM_KEEPER',
  'T5_OFF_TOTEM_KEEPER',
  'T6_OFF_TOTEM_KEEPER',
  'T7_OFF_TOTEM_KEEPER',
  'T8_OFF_TOTEM_KEEPER',
  'T4_OFF_CENSER_AVALON',
  'T5_OFF_CENSER_AVALON',
  'T6_OFF_CENSER_AVALON',
  'T7_OFF_CENSER_AVALON',
  'T8_OFF_CENSER_AVALON',
  'T3_OFF_TORCH',
  'T4_OFF_TORCH',
  'T5_OFF_TORCH',
  'T6_OFF_TORCH',
  'T7_OFF_TORCH',
  'T8_OFF_TORCH',
  'T4_OFF_HORN_KEEPER',
  'T5_OFF_HORN_KEEPER',
  'T6_OFF_HORN_KEEPER',
  'T7_OFF_HORN_KEEPER',
  'T8_OFF_HORN_KEEPER',
  'T4_OFF_TALISMAN_AVALON',
  'T5_OFF_TALISMAN_AVALON',
  'T6_OFF_TALISMAN_AVALON',
  'T7_OFF_TALISMAN_AVALON',
  'T8_OFF_TALISMAN_AVALON',
  'T4_OFF_LAMP_UNDEAD',
  'T5_OFF_LAMP_UNDEAD',
  'T6_OFF_LAMP_UNDEAD',
  'T7_OFF_LAMP_UNDEAD',
  'T8_OFF_LAMP_UNDEAD',
  'T4_OFF_JESTERCANE_HELL',
  'T5_OFF_JESTERCANE_HELL',
  'T6_OFF_JESTERCANE_HELL',
  'T7_OFF_JESTERCANE_HELL',
  'T8_OFF_JESTERCANE_HELL',
  'T2_CAPE',
  'T3_CAPE',
  'T4_CAPE',
  'T5_CAPE',
  'T6_CAPE',
  'T7_CAPE',
  'T8_CAPE',
  'T4_CAPEITEM_FW_BRIDGEWATCH',
  'T5_CAPEITEM_FW_BRIDGEWATCH',
  'T6_CAPEITEM_FW_BRIDGEWATCH',
  'T7_CAPEITEM_FW_BRIDGEWATCH',
  'T8_CAPEITEM_FW_BRIDGEWATCH',
  'T4_CAPEITEM_FW_FORTSTERLING',
  'T5_CAPEITEM_FW_FORTSTERLING',
  'T6_CAPEITEM_FW_FORTSTERLING',
  'T7_CAPEITEM_FW_FORTSTERLING',
  'T8_CAPEITEM_FW_FORTSTERLING',
  'T4_CAPEITEM_FW_LYMHURST',
  'T5_CAPEITEM_FW_LYMHURST',
  'T6_CAPEITEM_FW_LYMHURST',
  'T7_CAPEITEM_FW_LYMHURST',
  'T8_CAPEITEM_FW_LYMHURST',
  'T4_CAPEITEM_FW_MARTLOCK',
  'T5_CAPEITEM_FW_MARTLOCK',
  'T6_CAPEITEM_FW_MARTLOCK',
  'T7_CAPEITEM_FW_MARTLOCK',
  'T8_CAPEITEM_FW_MARTLOCK',
  'T4_CAPEITEM_FW_THETFORD',
  'T5_CAPEITEM_FW_THETFORD',
  'T6_CAPEITEM_FW_THETFORD',
  'T7_CAPEITEM_FW_THETFORD',
  'T8_CAPEITEM_FW_THETFORD',
  'T4_CAPEITEM_FW_CAERLEON',
  'T5_CAPEITEM_FW_CAERLEON',
  'T6_CAPEITEM_FW_CAERLEON',
  'T7_CAPEITEM_FW_CAERLEON',
  'T8_CAPEITEM_FW_CAERLEON',
  'T4_CAPEITEM_FW_BRECILIEN',
  'T5_CAPEITEM_FW_BRECILIEN',
  'T6_CAPEITEM_FW_BRECILIEN',
  'T7_CAPEITEM_FW_BRECILIEN',
  'T8_CAPEITEM_FW_BRECILIEN',
  'T4_CAPEITEM_AVALON',
  'T5_CAPEITEM_AVALON',
  'T6_CAPEITEM_AVALON',
  'T7_CAPEITEM_AVALON',
  'T8_CAPEITEM_AVALON',
  'T4_CAPEITEM_HERETIC',
  'T5_CAPEITEM_HERETIC',
  'T6_CAPEITEM_HERETIC',
  'T7_CAPEITEM_HERETIC',
  'T8_CAPEITEM_HERETIC',
  'T4_CAPEITEM_UNDEAD',
  'T5_CAPEITEM_UNDEAD',
  'T6_CAPEITEM_UNDEAD',
  'T7_CAPEITEM_UNDEAD',
  'T8_CAPEITEM_UNDEAD',
  'T4_CAPEITEM_KEEPER',
  'T5_CAPEITEM_KEEPER',
  'T6_CAPEITEM_KEEPER',
  'T7_CAPEITEM_KEEPER',
  'T8_CAPEITEM_KEEPER',
  'T4_CAPEITEM_MORGANA',
  'T5_CAPEITEM_MORGANA',
  'T6_CAPEITEM_MORGANA',
  'T7_CAPEITEM_MORGANA',
  'T8_CAPEITEM_MORGANA',
  'T4_CAPEITEM_DEMON',
  'T5_CAPEITEM_DEMON',
  'T6_CAPEITEM_DEMON',
  'T7_CAPEITEM_DEMON',
  'T8_CAPEITEM_DEMON',
  'T2_BAG',
  'T3_BAG',
  'T4_BAG',
  'T5_BAG',
  'T6_BAG',
  'T7_BAG',
  'T8_BAG',
  'T4_BAG_INSIGHT',
  'T5_BAG_INSIGHT',
  'T6_BAG_INSIGHT',
  'T7_BAG_INSIGHT',
  'T8_BAG_INSIGHT',
  'T1_2H_TOOL_PICK',
  'T2_2H_TOOL_PICK',
  'T3_2H_TOOL_PICK',
  'T4_2H_TOOL_PICK',
  'T5_2H_TOOL_PICK',
  'T6_2H_TOOL_PICK',
  'T7_2H_TOOL_PICK',
  'T8_2H_TOOL_PICK',
  'T4_2H_TOOL_PICK_AVALON',
  'T5_2H_TOOL_PICK_AVALON',
  'T6_2H_TOOL_PICK_AVALON',
  'T7_2H_TOOL_PICK_AVALON',
  'T8_2H_TOOL_PICK_AVALON',
  'T1_2H_TOOL_HAMMER',
  'T2_2H_TOOL_HAMMER',
  'T3_2H_TOOL_HAMMER',
  'T4_2H_TOOL_HAMMER',
  'T5_2H_TOOL_HAMMER',
  'T6_2H_TOOL_HAMMER',
  'T7_2H_TOOL_HAMMER',
  'T8_2H_TOOL_HAMMER',
  'T4_2H_TOOL_HAMMER_AVALON',
  'T5_2H_TOOL_HAMMER_AVALON',
  'T6_2H_TOOL_HAMMER_AVALON',
  'T7_2H_TOOL_HAMMER_AVALON',
  'T8_2H_TOOL_HAMMER_AVALON',
  'T1_2H_TOOL_AXE',
  'T2_2H_TOOL_AXE',
  'T3_2H_TOOL_AXE',
  'T4_2H_TOOL_AXE',
  'T5_2H_TOOL_AXE',
  'T6_2H_TOOL_AXE',
  'T7_2H_TOOL_AXE',
  'T8_2H_TOOL_AXE',
  'T4_2H_TOOL_AXE_AVALON',
  'T5_2H_TOOL_AXE_AVALON',
  'T6_2H_TOOL_AXE_AVALON',
  'T7_2H_TOOL_AXE_AVALON',
  'T8_2H_TOOL_AXE_AVALON',
  'T1_2H_TOOL_SICKLE',
  'T2_2H_TOOL_SICKLE',
  'T3_2H_TOOL_SICKLE',
  'T4_2H_TOOL_SICKLE',
  'T5_2H_TOOL_SICKLE',
  'T6_2H_TOOL_SICKLE',
  'T7_2H_TOOL_SICKLE',
  'T8_2H_TOOL_SICKLE',
  'T4_2H_TOOL_SICKLE_AVALON',
  'T5_2H_TOOL_SICKLE_AVALON',
  'T6_2H_TOOL_SICKLE_AVALON',
  'T7_2H_TOOL_SICKLE_AVALON',
  'T8_2H_TOOL_SICKLE_AVALON',
  'T1_2H_TOOL_KNIFE',
  'T2_2H_TOOL_KNIFE',
  'T3_2H_TOOL_KNIFE',
  'T4_2H_TOOL_KNIFE',
  'T5_2H_TOOL_KNIFE',
  'T6_2H_TOOL_KNIFE',
  'T7_2H_TOOL_KNIFE',
  'T8_2H_TOOL_KNIFE',
  'T4_2H_TOOL_KNIFE_AVALON',
  'T5_2H_TOOL_KNIFE_AVALON',
  'T6_2H_TOOL_KNIFE_AVALON',
  'T7_2H_TOOL_KNIFE_AVALON',
  'T8_2H_TOOL_KNIFE_AVALON',
  'T2_2H_TOOL_SIEGEHAMMER',
  'T3_2H_TOOL_SIEGEHAMMER',
  'T4_2H_TOOL_SIEGEHAMMER',
  'T5_2H_TOOL_SIEGEHAMMER',
  'T6_2H_TOOL_SIEGEHAMMER',
  'T7_2H_TOOL_SIEGEHAMMER',
  'T8_2H_TOOL_SIEGEHAMMER',
  'T4_2H_TOOL_SIEGEHAMMER_AVALON',
  'T5_2H_TOOL_SIEGEHAMMER_AVALON',
  'T6_2H_TOOL_SIEGEHAMMER_AVALON',
  'T7_2H_TOOL_SIEGEHAMMER_AVALON',
  'T8_2H_TOOL_SIEGEHAMMER_AVALON',
  'T3_2H_TOOL_FISHINGROD',
  'T4_2H_TOOL_FISHINGROD',
  'T5_2H_TOOL_FISHINGROD',
  'T6_2H_TOOL_FISHINGROD',
  'T7_2H_TOOL_FISHINGROD',
  'T8_2H_TOOL_FISHINGROD',
  'T4_2H_TOOL_FISHINGROD_AVALON',
  'T5_2H_TOOL_FISHINGROD_AVALON',
  'T6_2H_TOOL_FISHINGROD_AVALON',
  'T7_2H_TOOL_FISHINGROD_AVALON',
  'T8_2H_TOOL_FISHINGROD_AVALON',
  'UNIQUE_MOUNT_ARMORED_HORSE_KNIGHT_01_TEST',
  'T2_MOUNT_MULE',
  'T3_MOUNT_HORSE',
  'T4_MOUNT_HORSE',
  'T5_MOUNT_HORSE',
  'T6_MOUNT_HORSE',
  'T7_MOUNT_HORSE',
  'T8_MOUNT_HORSE',
  'T5_MOUNT_ARMORED_HORSE',
  'T6_MOUNT_ARMORED_HORSE',
  'T7_MOUNT_ARMORED_HORSE',
  'T8_MOUNT_ARMORED_HORSE',
  'T3_MOUNT_OX',
  'T4_MOUNT_OX',
  'T5_MOUNT_OX',
  'T6_MOUNT_OX',
  'T7_MOUNT_OX',
  'T8_MOUNT_OX',
  'T4_MOUNT_GIANTSTAG',
  'T6_MOUNT_GIANTSTAG_MOOSE',
  'T6_MOUNT_DIREWOLF',
  'T7_MOUNT_DIREBOAR',
  'T7_MOUNT_SWAMPDRAGON',
  'T8_MOUNT_DIREBEAR',
  'T8_MOUNT_MAMMOTH_TRANSPORT',
  'T5_MOUNT_MOABIRD_FW_BRIDGEWATCH',
  'T5_MOUNT_DIREBEAR_FW_FORTSTERLING',
  'T5_MOUNT_DIREBOAR_FW_LYMHURST',
  'T5_MOUNT_RAM_FW_MARTLOCK',
  'T5_MOUNT_SWAMPDRAGON_FW_THETFORD',
  'T5_MOUNT_GREYWOLF_FW_CAERLEON',
  'T5_MOUNT_OWL_FW_BRECILIEN',
  'T8_MOUNT_MOABIRD_FW_BRIDGEWATCH_ELITE',
  'T8_MOUNT_DIREBEAR_FW_FORTSTERLING_ELITE',
  'T8_MOUNT_DIREBOAR_FW_LYMHURST_ELITE',
  'T8_MOUNT_RAM_FW_MARTLOCK_ELITE',
  'T8_MOUNT_SWAMPDRAGON_FW_THETFORD_ELITE',
  'T8_MOUNT_GREYWOLF_FW_CAERLEON_ELITE',
  'T8_MOUNT_OWL_FW_BRECILIEN_ELITE',
  'T7_MOUNT_SWAMPDRAGON_BATTLE',
  'T7_MOUNT_ARMORED_SWAMPDRAGON_BATTLE',
  'T6_MOUNT_SIEGE_BALLISTA',
  'T8_MOUNT_RABBIT_EASTER',
  'UNIQUE_MOUNT_RAM_XMAS',
  'T7_MOUNT_SWAMPDRAGON_AVALON_BASILISK',
  'UNIQUE_MOUNT_RAM_TELLAFRIEND',
  'UNIQUE_MOUNT_MOABIRD_TELLAFRIEND',
  'UNIQUE_MOUNT_BAT_TELLAFRIEND',
  'UNIQUE_MOUNT_GIANTTOAD_TELLAFRIEND',
  'UNIQUE_MOUNT_PANDA_TELLAFRIEND',
  'UNIQUE_MOUNT_GIANTTOAD_02_TELLAFRIEND',
  'T5_MOUNT_ARMORED_HORSE_SKIN_01',
  'T6_MOUNT_ARMORED_HORSE_SKIN_01',
  'T7_MOUNT_ARMORED_HORSE_SKIN_01',
  'T8_MOUNT_ARMORED_HORSE_SKIN_01',
  'UNIQUE_MOUNT_BAT_PERSONAL',
  'T7_MOUNT_MONITORLIZARD_ADC',
  'T7_MOUNT_HUSKY_ADC',
  'T6_MOUNT_FROSTRAM_ADC',
  'T7_MOUNT_TERRORBIRD_ADC',
  'UNIQUE_MOUNT_BEAR_KEEPER_ADC',
  'UNIQUE_MOUNT_BLACK_PANTHER_ADC',
  'UNIQUE_MOUNT_MORGANA_RAVEN_ADC',
  'UNIQUE_MOUNT_GIANT_HORSE_ADC',
  'UNIQUE_MOUNT_UNDEAD_DIREBOAR_ADC',
  'UNIQUE_MOUNT_DIVINE_OWL_ADC',
  'UNIQUE_MOUNT_HERETIC_MULE_ADC',
  'UNIQUE_MOUNT_RHINO_SEASON_CRYSTAL',
  'UNIQUE_MOUNT_RHINO_SEASON_GOLD',
  'UNIQUE_MOUNT_RHINO_SEASON_SILVER',
  'UNIQUE_MOUNT_RHINO_SEASON_BRONZE',
  'UNIQUE_MOUNT_TOWER_CHARIOT_CRYSTAL',
  'UNIQUE_MOUNT_TOWER_CHARIOT_GOLD',
  'UNIQUE_MOUNT_TOWER_CHARIOT_SILVER',
  'UNIQUE_MOUNT_ARMORED_EAGLE_CRYSTAL',
  'UNIQUE_MOUNT_ARMORED_EAGLE_GOLD',
  'UNIQUE_MOUNT_ARMORED_EAGLE_SILVER',
  'UNIQUE_MOUNT_BEETLE_CRYSTAL',
  'UNIQUE_MOUNT_BEETLE_GOLD',
  'UNIQUE_MOUNT_BEETLE_SILVER',
  'UNIQUE_MOUNT_BEHEMOTH_CRYSTAL',
  'UNIQUE_MOUNT_BEHEMOTH_GOLD',
  'UNIQUE_MOUNT_BEHEMOTH_SILVER',
  'UNIQUE_MOUNT_ENT_CRYSTAL',
  'UNIQUE_MOUNT_ENT_GOLD',
  'UNIQUE_MOUNT_ENT_SILVER',
  'UNIQUE_MOUNT_BATTLESPIDER_CRYSTAL',
  'UNIQUE_MOUNT_BATTLESPIDER_GOLD',
  'UNIQUE_MOUNT_BATTLESPIDER_SILVER',
  'UNIQUE_MOUNT_BASTION_CRYSTAL',
  'UNIQUE_MOUNT_BASTION_GOLD',
  'UNIQUE_MOUNT_BASTION_SILVER',
  'UNIQUE_MOUNT_JUGGERNAUT_CRYSTAL',
  'UNIQUE_MOUNT_JUGGERNAUT_GOLD',
  'UNIQUE_MOUNT_JUGGERNAUT_SILVER',
  'UNIQUE_MOUNT_TANKBEETLE_CRYSTAL',
  'UNIQUE_MOUNT_TANKBEETLE_GOLD',
  'UNIQUE_MOUNT_TANKBEETLE_SILVER',
  'UNIQUE_MOUNT_RHINO_TELLAFRIEND',
  'UNIQUE_MOUNT_GIANTSTAG_FOUNDER_EPIC',
  'UNIQUE_INGREDIENT_ANCHOR_FOUNDER_LEGENDARY',
  'UNIQUE_SHOES_RUBBERBANDING',
  'UNIQUE_HEAD_VANITY_FOUNDER_ASIA_CRYSTAL',
  'UNIQUE_ARMOR_VANITY_FOUNDER_ASIA_CRYSTAL',
  'UNIQUE_SHOES_VANITY_FOUNDER_ASIA_CRYSTAL',
  'UNIQUE_CAPE_VANITY_FOUNDER_ASIA_CRYSTAL',
  'UNIQUE_HEAD_VANITY_FOUNDER_ASIA_GOLD',
  'UNIQUE_ARMOR_VANITY_FOUNDER_ASIA_GOLD',
  'UNIQUE_SHOES_VANITY_FOUNDER_ASIA_GOLD',
  'UNIQUE_CAPE_VANITY_FOUNDER_ASIA_GOLD',
  'UNIQUE_HEAD_VANITY_WARRIOR_KING',
  'UNIQUE_ARMOR_VANITY_WARRIOR_KING',
  'UNIQUE_SHOES_VANITY_WARRIOR_KING',
  'UNIQUE_CAPE_VANITY_WARRIOR_KING',
  'UNIQUE_HEAD_VANITY_WARRIOR_KNIGHT',
  'UNIQUE_ARMOR_VANITY_WARRIOR_KNIGHT',
  'UNIQUE_SHOES_VANITY_WARRIOR_KNIGHT',
  'UNIQUE_CAPE_VANITY_WARRIOR_KNIGHT',
  'UNIQUE_HEAD_VANITY_DARK_SORCERER',
  'UNIQUE_ARMOR_VANITY_DARK_SORCERER',
  'UNIQUE_SHOES_VANITY_DARK_SORCERER',
  'UNIQUE_CAPE_VANITY_DARK_SORCERER',
  'UNIQUE_HEAD_VANITY_DARK_NOVICE',
  'UNIQUE_ARMOR_VANITY_DARK_NOVICE',
  'UNIQUE_SHOES_VANITY_DARK_NOVICE',
  'UNIQUE_CAPE_VANITY_DARK_NOVICE',
  'UNIQUE_INTERNAL_HEAD_GAMEMASTER',
  'UNIQUE_INTERNAL_ARMOR_GAMEMASTER',
  'UNIQUE_INTERNAL_SHOES_GAMEMASTER',
  'UNIQUE_INTERNAL_OFF_SCROLL_GAMEMASTER',
  'T2_HEAD_PLATE_SET1',
  'T3_HEAD_PLATE_SET1',
  'T4_HEAD_PLATE_SET1',
  'T5_HEAD_PLATE_SET1',
  'T6_HEAD_PLATE_SET1',
  'T7_HEAD_PLATE_SET1',
  'T8_HEAD_PLATE_SET1',
  'T2_ARMOR_PLATE_SET1',
  'T3_ARMOR_PLATE_SET1',
  'T4_ARMOR_PLATE_SET1',
  'T5_ARMOR_PLATE_SET1',
  'T6_ARMOR_PLATE_SET1',
  'T7_ARMOR_PLATE_SET1',
  'T8_ARMOR_PLATE_SET1',
  'T2_SHOES_PLATE_SET1',
  'T3_SHOES_PLATE_SET1',
  'T4_SHOES_PLATE_SET1',
  'T5_SHOES_PLATE_SET1',
  'T6_SHOES_PLATE_SET1',
  'T7_SHOES_PLATE_SET1',
  'T8_SHOES_PLATE_SET1',
  'T4_HEAD_PLATE_SET2',
  'T5_HEAD_PLATE_SET2',
  'T6_HEAD_PLATE_SET2',
  'T7_HEAD_PLATE_SET2',
  'T8_HEAD_PLATE_SET2',
  'T4_ARMOR_PLATE_SET2',
  'T5_ARMOR_PLATE_SET2',
  'T6_ARMOR_PLATE_SET2',
  'T7_ARMOR_PLATE_SET2',
  'T8_ARMOR_PLATE_SET2',
  'T4_SHOES_PLATE_SET2',
  'T5_SHOES_PLATE_SET2',
  'T6_SHOES_PLATE_SET2',
  'T7_SHOES_PLATE_SET2',
  'T8_SHOES_PLATE_SET2',
  'T4_HEAD_PLATE_SET3',
  'T5_HEAD_PLATE_SET3',
  'T6_HEAD_PLATE_SET3',
  'T7_HEAD_PLATE_SET3',
  'T8_HEAD_PLATE_SET3',
  'T4_ARMOR_PLATE_SET3',
  'T5_ARMOR_PLATE_SET3',
  'T6_ARMOR_PLATE_SET3',
  'T7_ARMOR_PLATE_SET3',
  'T8_ARMOR_PLATE_SET3',
  'T4_SHOES_PLATE_SET3',
  'T5_SHOES_PLATE_SET3',
  'T6_SHOES_PLATE_SET3',
  'T7_SHOES_PLATE_SET3',
  'T8_SHOES_PLATE_SET3',
  'T4_HEAD_PLATE_UNDEAD',
  'T5_HEAD_PLATE_UNDEAD',
  'T6_HEAD_PLATE_UNDEAD',
  'T7_HEAD_PLATE_UNDEAD',
  'T8_HEAD_PLATE_UNDEAD',
  'T4_ARMOR_PLATE_UNDEAD',
  'T5_ARMOR_PLATE_UNDEAD',
  'T6_ARMOR_PLATE_UNDEAD',
  'T7_ARMOR_PLATE_UNDEAD',
  'T8_ARMOR_PLATE_UNDEAD',
  'T4_SHOES_PLATE_UNDEAD',
  'T5_SHOES_PLATE_UNDEAD',
  'T6_SHOES_PLATE_UNDEAD',
  'T7_SHOES_PLATE_UNDEAD',
  'T8_SHOES_PLATE_UNDEAD',
  'T4_HEAD_PLATE_HELL',
  'T5_HEAD_PLATE_HELL',
  'T6_HEAD_PLATE_HELL',
  'T7_HEAD_PLATE_HELL',
  'T8_HEAD_PLATE_HELL',
  'T4_ARMOR_PLATE_HELL',
  'T5_ARMOR_PLATE_HELL',
  'T6_ARMOR_PLATE_HELL',
  'T7_ARMOR_PLATE_HELL',
  'T8_ARMOR_PLATE_HELL',
  'T4_SHOES_PLATE_HELL',
  'T5_SHOES_PLATE_HELL',
  'T6_SHOES_PLATE_HELL',
  'T7_SHOES_PLATE_HELL',
  'T8_SHOES_PLATE_HELL',
  'T4_HEAD_PLATE_KEEPER',
  'T5_HEAD_PLATE_KEEPER',
  'T6_HEAD_PLATE_KEEPER',
  'T7_HEAD_PLATE_KEEPER',
  'T8_HEAD_PLATE_KEEPER',
  'T4_ARMOR_PLATE_KEEPER',
  'T5_ARMOR_PLATE_KEEPER',
  'T6_ARMOR_PLATE_KEEPER',
  'T7_ARMOR_PLATE_KEEPER',
  'T8_ARMOR_PLATE_KEEPER',
  'T4_SHOES_PLATE_KEEPER',
  'T5_SHOES_PLATE_KEEPER',
  'T6_SHOES_PLATE_KEEPER',
  'T7_SHOES_PLATE_KEEPER',
  'T8_SHOES_PLATE_KEEPER',
  'T4_HEAD_PLATE_FEY',
  'T5_HEAD_PLATE_FEY',
  'T6_HEAD_PLATE_FEY',
  'T7_HEAD_PLATE_FEY',
  'T8_HEAD_PLATE_FEY',
  'T4_ARMOR_PLATE_FEY',
  'T5_ARMOR_PLATE_FEY',
  'T6_ARMOR_PLATE_FEY',
  'T7_ARMOR_PLATE_FEY',
  'T8_ARMOR_PLATE_FEY',
  'T4_SHOES_PLATE_FEY',
  'T5_SHOES_PLATE_FEY',
  'T6_SHOES_PLATE_FEY',
  'T7_SHOES_PLATE_FEY',
  'T8_SHOES_PLATE_FEY',
  'T4_HEAD_PLATE_AVALON',
  'T5_HEAD_PLATE_AVALON',
  'T6_HEAD_PLATE_AVALON',
  'T7_HEAD_PLATE_AVALON',
  'T8_HEAD_PLATE_AVALON',
  'T4_ARMOR_PLATE_AVALON',
  'T5_ARMOR_PLATE_AVALON',
  'T6_ARMOR_PLATE_AVALON',
  'T7_ARMOR_PLATE_AVALON',
  'T8_ARMOR_PLATE_AVALON',
  'T4_SHOES_PLATE_AVALON',
  'T5_SHOES_PLATE_AVALON',
  'T6_SHOES_PLATE_AVALON',
  'T7_SHOES_PLATE_AVALON',
  'T8_SHOES_PLATE_AVALON',
  'T1_HEAD_LEATHER_SET1',
  'T2_HEAD_LEATHER_SET1',
  'T3_HEAD_LEATHER_SET1',
  'T4_HEAD_LEATHER_SET1',
  'T5_HEAD_LEATHER_SET1',
  'T6_HEAD_LEATHER_SET1',
  'T7_HEAD_LEATHER_SET1',
  'T8_HEAD_LEATHER_SET1',
  'T1_ARMOR_LEATHER_SET1',
  'T2_ARMOR_LEATHER_SET1',
  'T3_ARMOR_LEATHER_SET1',
  'T4_ARMOR_LEATHER_SET1',
  'T5_ARMOR_LEATHER_SET1',
  'T6_ARMOR_LEATHER_SET1',
  'T7_ARMOR_LEATHER_SET1',
  'T8_ARMOR_LEATHER_SET1',
  'T1_SHOES_LEATHER_SET1',
  'T2_SHOES_LEATHER_SET1',
  'T3_SHOES_LEATHER_SET1',
  'T4_SHOES_LEATHER_SET1',
  'T5_SHOES_LEATHER_SET1',
  'T6_SHOES_LEATHER_SET1',
  'T7_SHOES_LEATHER_SET1',
  'T8_SHOES_LEATHER_SET1',
  'T4_HEAD_LEATHER_SET2',
  'T5_HEAD_LEATHER_SET2',
  'T6_HEAD_LEATHER_SET2',
  'T7_HEAD_LEATHER_SET2',
  'T8_HEAD_LEATHER_SET2',
  'T4_ARMOR_LEATHER_SET2',
  'T5_ARMOR_LEATHER_SET2',
  'T6_ARMOR_LEATHER_SET2',
  'T7_ARMOR_LEATHER_SET2',
  'T8_ARMOR_LEATHER_SET2',
  'T4_SHOES_LEATHER_SET2',
  'T5_SHOES_LEATHER_SET2',
  'T6_SHOES_LEATHER_SET2',
  'T7_SHOES_LEATHER_SET2',
  'T8_SHOES_LEATHER_SET2',
  'T4_HEAD_LEATHER_SET3',
  'T5_HEAD_LEATHER_SET3',
  'T6_HEAD_LEATHER_SET3',
  'T7_HEAD_LEATHER_SET3',
  'T8_HEAD_LEATHER_SET3',
  'T4_ARMOR_LEATHER_SET3',
  'T5_ARMOR_LEATHER_SET3',
  'T6_ARMOR_LEATHER_SET3',
  'T7_ARMOR_LEATHER_SET3',
  'T8_ARMOR_LEATHER_SET3',
  'T4_SHOES_LEATHER_SET3',
  'T5_SHOES_LEATHER_SET3',
  'T6_SHOES_LEATHER_SET3',
  'T7_SHOES_LEATHER_SET3',
  'T8_SHOES_LEATHER_SET3',
  'T4_HEAD_LEATHER_MORGANA',
  'T5_HEAD_LEATHER_MORGANA',
  'T6_HEAD_LEATHER_MORGANA',
  'T7_HEAD_LEATHER_MORGANA',
  'T8_HEAD_LEATHER_MORGANA',
  'T4_ARMOR_LEATHER_MORGANA',
  'T5_ARMOR_LEATHER_MORGANA',
  'T6_ARMOR_LEATHER_MORGANA',
  'T7_ARMOR_LEATHER_MORGANA',
  'T8_ARMOR_LEATHER_MORGANA',
  'T4_SHOES_LEATHER_MORGANA',
  'T5_SHOES_LEATHER_MORGANA',
  'T6_SHOES_LEATHER_MORGANA',
  'T7_SHOES_LEATHER_MORGANA',
  'T8_SHOES_LEATHER_MORGANA',
  'T4_HEAD_LEATHER_HELL',
  'T5_HEAD_LEATHER_HELL',
  'T6_HEAD_LEATHER_HELL',
  'T7_HEAD_LEATHER_HELL',
  'T8_HEAD_LEATHER_HELL',
  'T4_ARMOR_LEATHER_HELL',
  'T5_ARMOR_LEATHER_HELL',
  'T6_ARMOR_LEATHER_HELL',
  'T7_ARMOR_LEATHER_HELL',
  'T8_ARMOR_LEATHER_HELL',
  'T4_SHOES_LEATHER_HELL',
  'T5_SHOES_LEATHER_HELL',
  'T6_SHOES_LEATHER_HELL',
  'T7_SHOES_LEATHER_HELL',
  'T8_SHOES_LEATHER_HELL',
  'T4_HEAD_LEATHER_UNDEAD',
  'T5_HEAD_LEATHER_UNDEAD',
  'T6_HEAD_LEATHER_UNDEAD',
  'T7_HEAD_LEATHER_UNDEAD',
  'T8_HEAD_LEATHER_UNDEAD',
  'T4_ARMOR_LEATHER_UNDEAD',
  'T5_ARMOR_LEATHER_UNDEAD',
  'T6_ARMOR_LEATHER_UNDEAD',
  'T7_ARMOR_LEATHER_UNDEAD',
  'T8_ARMOR_LEATHER_UNDEAD',
  'T4_SHOES_LEATHER_UNDEAD',
  'T5_SHOES_LEATHER_UNDEAD',
  'T6_SHOES_LEATHER_UNDEAD',
  'T7_SHOES_LEATHER_UNDEAD',
  'T8_SHOES_LEATHER_UNDEAD',
  'T4_HEAD_LEATHER_FEY',
  'T5_HEAD_LEATHER_FEY',
  'T6_HEAD_LEATHER_FEY',
  'T7_HEAD_LEATHER_FEY',
  'T8_HEAD_LEATHER_FEY',
  'T4_ARMOR_LEATHER_FEY',
  'T5_ARMOR_LEATHER_FEY',
  'T6_ARMOR_LEATHER_FEY',
  'T7_ARMOR_LEATHER_FEY',
  'T8_ARMOR_LEATHER_FEY',
  'T4_SHOES_LEATHER_FEY',
  'T5_SHOES_LEATHER_FEY',
  'T6_SHOES_LEATHER_FEY',
  'T7_SHOES_LEATHER_FEY',
  'T8_SHOES_LEATHER_FEY',
  'T4_HEAD_LEATHER_AVALON',
  'T5_HEAD_LEATHER_AVALON',
  'T6_HEAD_LEATHER_AVALON',
  'T7_HEAD_LEATHER_AVALON',
  'T8_HEAD_LEATHER_AVALON',
  'T4_ARMOR_LEATHER_AVALON',
  'T5_ARMOR_LEATHER_AVALON',
  'T6_ARMOR_LEATHER_AVALON',
  'T7_ARMOR_LEATHER_AVALON',
  'T8_ARMOR_LEATHER_AVALON',
  'T4_SHOES_LEATHER_AVALON',
  'T5_SHOES_LEATHER_AVALON',
  'T6_SHOES_LEATHER_AVALON',
  'T7_SHOES_LEATHER_AVALON',
  'T8_SHOES_LEATHER_AVALON',
  'T2_HEAD_CLOTH_SET1',
  'T3_HEAD_CLOTH_SET1',
  'T4_HEAD_CLOTH_SET1',
  'T5_HEAD_CLOTH_SET1',
  'T6_HEAD_CLOTH_SET1',
  'T7_HEAD_CLOTH_SET1',
  'T8_HEAD_CLOTH_SET1',
  'T2_ARMOR_CLOTH_SET1',
  'T3_ARMOR_CLOTH_SET1',
  'T4_ARMOR_CLOTH_SET1',
  'T5_ARMOR_CLOTH_SET1',
  'T6_ARMOR_CLOTH_SET1',
  'T7_ARMOR_CLOTH_SET1',
  'T8_ARMOR_CLOTH_SET1',
  'T2_SHOES_CLOTH_SET1',
  'T3_SHOES_CLOTH_SET1',
  'T4_SHOES_CLOTH_SET1',
  'T5_SHOES_CLOTH_SET1',
  'T6_SHOES_CLOTH_SET1',
  'T7_SHOES_CLOTH_SET1',
  'T8_SHOES_CLOTH_SET1',
  'T4_HEAD_CLOTH_SET2',
  'T5_HEAD_CLOTH_SET2',
  'T6_HEAD_CLOTH_SET2',
  'T7_HEAD_CLOTH_SET2',
  'T8_HEAD_CLOTH_SET2',
  'T4_ARMOR_CLOTH_SET2',
  'T5_ARMOR_CLOTH_SET2',
  'T6_ARMOR_CLOTH_SET2',
  'T7_ARMOR_CLOTH_SET2',
  'T8_ARMOR_CLOTH_SET2',
  'T4_SHOES_CLOTH_SET2',
  'T5_SHOES_CLOTH_SET2',
  'T6_SHOES_CLOTH_SET2',
  'T7_SHOES_CLOTH_SET2',
  'T8_SHOES_CLOTH_SET2',
  'T4_HEAD_CLOTH_SET3',
  'T5_HEAD_CLOTH_SET3',
  'T6_HEAD_CLOTH_SET3',
  'T7_HEAD_CLOTH_SET3',
  'T8_HEAD_CLOTH_SET3',
  'T4_ARMOR_CLOTH_SET3',
  'T5_ARMOR_CLOTH_SET3',
  'T6_ARMOR_CLOTH_SET3',
  'T7_ARMOR_CLOTH_SET3',
  'T8_ARMOR_CLOTH_SET3',
  'T4_SHOES_CLOTH_SET3',
  'T5_SHOES_CLOTH_SET3',
  'T6_SHOES_CLOTH_SET3',
  'T7_SHOES_CLOTH_SET3',
  'T8_SHOES_CLOTH_SET3',
  'T4_HEAD_CLOTH_KEEPER',
  'T5_HEAD_CLOTH_KEEPER',
  'T6_HEAD_CLOTH_KEEPER',
  'T7_HEAD_CLOTH_KEEPER',
  'T8_HEAD_CLOTH_KEEPER',
  'T4_ARMOR_CLOTH_KEEPER',
  'T5_ARMOR_CLOTH_KEEPER',
  'T6_ARMOR_CLOTH_KEEPER',
  'T7_ARMOR_CLOTH_KEEPER',
  'T8_ARMOR_CLOTH_KEEPER',
  'T4_SHOES_CLOTH_KEEPER',
  'T5_SHOES_CLOTH_KEEPER',
  'T6_SHOES_CLOTH_KEEPER',
  'T7_SHOES_CLOTH_KEEPER',
  'T8_SHOES_CLOTH_KEEPER',
  'T4_HEAD_CLOTH_HELL',
  'T5_HEAD_CLOTH_HELL',
  'T6_HEAD_CLOTH_HELL',
  'T7_HEAD_CLOTH_HELL',
  'T8_HEAD_CLOTH_HELL',
  'T4_ARMOR_CLOTH_HELL',
  'T5_ARMOR_CLOTH_HELL',
  'T6_ARMOR_CLOTH_HELL',
  'T7_ARMOR_CLOTH_HELL',
  'T8_ARMOR_CLOTH_HELL',
  'T4_SHOES_CLOTH_HELL',
  'T5_SHOES_CLOTH_HELL',
  'T6_SHOES_CLOTH_HELL',
  'T7_SHOES_CLOTH_HELL',
  'T8_SHOES_CLOTH_HELL',
  'T4_HEAD_CLOTH_MORGANA',
  'T5_HEAD_CLOTH_MORGANA',
  'T6_HEAD_CLOTH_MORGANA',
  'T7_HEAD_CLOTH_MORGANA',
  'T8_HEAD_CLOTH_MORGANA',
  'T4_ARMOR_CLOTH_MORGANA',
  'T5_ARMOR_CLOTH_MORGANA',
  'T6_ARMOR_CLOTH_MORGANA',
  'T7_ARMOR_CLOTH_MORGANA',
  'T8_ARMOR_CLOTH_MORGANA',
  'T4_SHOES_CLOTH_MORGANA',
  'T5_SHOES_CLOTH_MORGANA',
  'T6_SHOES_CLOTH_MORGANA',
  'T7_SHOES_CLOTH_MORGANA',
  'T8_SHOES_CLOTH_MORGANA',
  'T4_HEAD_CLOTH_FEY',
  'T5_HEAD_CLOTH_FEY',
  'T6_HEAD_CLOTH_FEY',
  'T7_HEAD_CLOTH_FEY',
  'T8_HEAD_CLOTH_FEY',
  'T4_ARMOR_CLOTH_FEY',
  'T5_ARMOR_CLOTH_FEY',
  'T6_ARMOR_CLOTH_FEY',
  'T7_ARMOR_CLOTH_FEY',
  'T8_ARMOR_CLOTH_FEY',
  'T4_SHOES_CLOTH_FEY',
  'T5_SHOES_CLOTH_FEY',
  'T6_SHOES_CLOTH_FEY',
  'T7_SHOES_CLOTH_FEY',
  'T8_SHOES_CLOTH_FEY',
  'T4_HEAD_CLOTH_AVALON',
  'T5_HEAD_CLOTH_AVALON',
  'T6_HEAD_CLOTH_AVALON',
  'T7_HEAD_CLOTH_AVALON',
  'T8_HEAD_CLOTH_AVALON',
  'T4_ARMOR_CLOTH_AVALON',
  'T5_ARMOR_CLOTH_AVALON',
  'T6_ARMOR_CLOTH_AVALON',
  'T7_ARMOR_CLOTH_AVALON',
  'T8_ARMOR_CLOTH_AVALON',
  'T4_SHOES_CLOTH_AVALON',
  'T5_SHOES_CLOTH_AVALON',
  'T6_SHOES_CLOTH_AVALON',
  'T7_SHOES_CLOTH_AVALON',
  'T8_SHOES_CLOTH_AVALON',
  'T4_HEAD_CLOTH_ROYAL',
  'T5_HEAD_CLOTH_ROYAL',
  'T6_HEAD_CLOTH_ROYAL',
  'T7_HEAD_CLOTH_ROYAL',
  'T8_HEAD_CLOTH_ROYAL',
  'T4_ARMOR_CLOTH_ROYAL',
  'T5_ARMOR_CLOTH_ROYAL',
  'T6_ARMOR_CLOTH_ROYAL',
  'T7_ARMOR_CLOTH_ROYAL',
  'T8_ARMOR_CLOTH_ROYAL',
  'T4_SHOES_CLOTH_ROYAL',
  'T5_SHOES_CLOTH_ROYAL',
  'T6_SHOES_CLOTH_ROYAL',
  'T7_SHOES_CLOTH_ROYAL',
  'T8_SHOES_CLOTH_ROYAL',
  'T4_HEAD_LEATHER_ROYAL',
  'T5_HEAD_LEATHER_ROYAL',
  'T6_HEAD_LEATHER_ROYAL',
  'T7_HEAD_LEATHER_ROYAL',
  'T8_HEAD_LEATHER_ROYAL',
  'T4_ARMOR_LEATHER_ROYAL',
  'T5_ARMOR_LEATHER_ROYAL',
  'T6_ARMOR_LEATHER_ROYAL',
  'T7_ARMOR_LEATHER_ROYAL',
  'T8_ARMOR_LEATHER_ROYAL',
  'T4_SHOES_LEATHER_ROYAL',
  'T5_SHOES_LEATHER_ROYAL',
  'T6_SHOES_LEATHER_ROYAL',
  'T7_SHOES_LEATHER_ROYAL',
  'T8_SHOES_LEATHER_ROYAL',
  'T4_HEAD_PLATE_ROYAL',
  'T5_HEAD_PLATE_ROYAL',
  'T6_HEAD_PLATE_ROYAL',
  'T7_HEAD_PLATE_ROYAL',
  'T8_HEAD_PLATE_ROYAL',
  'T4_ARMOR_PLATE_ROYAL',
  'T5_ARMOR_PLATE_ROYAL',
  'T6_ARMOR_PLATE_ROYAL',
  'T7_ARMOR_PLATE_ROYAL',
  'T8_ARMOR_PLATE_ROYAL',
  'T4_SHOES_PLATE_ROYAL',
  'T5_SHOES_PLATE_ROYAL',
  'T6_SHOES_PLATE_ROYAL',
  'T7_SHOES_PLATE_ROYAL',
  'T8_SHOES_PLATE_ROYAL',
  'T2_JOURNAL_WOOD',
  'T3_JOURNAL_WOOD',
  'T4_JOURNAL_WOOD',
  'T5_JOURNAL_WOOD',
  'T6_JOURNAL_WOOD',
  'T7_JOURNAL_WOOD',
  'T8_JOURNAL_WOOD',
  'T2_JOURNAL_STONE',
  'T3_JOURNAL_STONE',
  'T4_JOURNAL_STONE',
  'T5_JOURNAL_STONE',
  'T6_JOURNAL_STONE',
  'T7_JOURNAL_STONE',
  'T8_JOURNAL_STONE',
  'T2_JOURNAL_ORE',
  'T3_JOURNAL_ORE',
  'T4_JOURNAL_ORE',
  'T5_JOURNAL_ORE',
  'T6_JOURNAL_ORE',
  'T7_JOURNAL_ORE',
  'T8_JOURNAL_ORE',
  'T2_JOURNAL_FIBER',
  'T3_JOURNAL_FIBER',
  'T4_JOURNAL_FIBER',
  'T5_JOURNAL_FIBER',
  'T6_JOURNAL_FIBER',
  'T7_JOURNAL_FIBER',
  'T8_JOURNAL_FIBER',
  'T2_JOURNAL_HIDE',
  'T3_JOURNAL_HIDE',
  'T4_JOURNAL_HIDE',
  'T5_JOURNAL_HIDE',
  'T6_JOURNAL_HIDE',
  'T7_JOURNAL_HIDE',
  'T8_JOURNAL_HIDE',
  'T2_JOURNAL_WARRIOR',
  'T3_JOURNAL_WARRIOR',
  'T4_JOURNAL_WARRIOR',
  'T5_JOURNAL_WARRIOR',
  'T6_JOURNAL_WARRIOR',
  'T7_JOURNAL_WARRIOR',
  'T8_JOURNAL_WARRIOR',
  'T2_JOURNAL_HUNTER',
  'T3_JOURNAL_HUNTER',
  'T4_JOURNAL_HUNTER',
  'T5_JOURNAL_HUNTER',
  'T6_JOURNAL_HUNTER',
  'T7_JOURNAL_HUNTER',
  'T8_JOURNAL_HUNTER',
  'T2_JOURNAL_MAGE',
  'T3_JOURNAL_MAGE',
  'T4_JOURNAL_MAGE',
  'T5_JOURNAL_MAGE',
  'T6_JOURNAL_MAGE',
  'T7_JOURNAL_MAGE',
  'T8_JOURNAL_MAGE',
  'T2_JOURNAL_TOOLMAKER',
  'T3_JOURNAL_TOOLMAKER',
  'T4_JOURNAL_TOOLMAKER',
  'T5_JOURNAL_TOOLMAKER',
  'T6_JOURNAL_TOOLMAKER',
  'T7_JOURNAL_TOOLMAKER',
  'T8_JOURNAL_TOOLMAKER',
  'T2_JOURNAL_MERCENARY',
  'T3_JOURNAL_MERCENARY',
  'T4_JOURNAL_MERCENARY',
  'T5_JOURNAL_MERCENARY',
  'T6_JOURNAL_MERCENARY',
  'T7_JOURNAL_MERCENARY',
  'T8_JOURNAL_MERCENARY',
  'T2_JOURNAL_TROPHY_GENERAL',
  'T3_JOURNAL_TROPHY_GENERAL',
  'T4_JOURNAL_TROPHY_GENERAL',
  'T5_JOURNAL_TROPHY_GENERAL',
  'T6_JOURNAL_TROPHY_GENERAL',
  'T7_JOURNAL_TROPHY_GENERAL',
  'T8_JOURNAL_TROPHY_GENERAL',
  'T2_JOURNAL_TROPHY_MERCENARY',
  'T3_JOURNAL_TROPHY_MERCENARY',
  'T4_JOURNAL_TROPHY_MERCENARY',
  'T5_JOURNAL_TROPHY_MERCENARY',
  'T6_JOURNAL_TROPHY_MERCENARY',
  'T7_JOURNAL_TROPHY_MERCENARY',
  'T8_JOURNAL_TROPHY_MERCENARY',
  'T2_JOURNAL_TROPHY_HIDE',
  'T3_JOURNAL_TROPHY_HIDE',
  'T4_JOURNAL_TROPHY_HIDE',
  'T5_JOURNAL_TROPHY_HIDE',
  'T6_JOURNAL_TROPHY_HIDE',
  'T7_JOURNAL_TROPHY_HIDE',
  'T8_JOURNAL_TROPHY_HIDE',
  'T2_JOURNAL_TROPHY_WOOD',
  'T3_JOURNAL_TROPHY_WOOD',
  'T4_JOURNAL_TROPHY_WOOD',
  'T5_JOURNAL_TROPHY_WOOD',
  'T6_JOURNAL_TROPHY_WOOD',
  'T7_JOURNAL_TROPHY_WOOD',
  'T8_JOURNAL_TROPHY_WOOD',
  'T2_JOURNAL_TROPHY_STONE',
  'T3_JOURNAL_TROPHY_STONE',
  'T4_JOURNAL_TROPHY_STONE',
  'T5_JOURNAL_TROPHY_STONE',
  'T6_JOURNAL_TROPHY_STONE',
  'T7_JOURNAL_TROPHY_STONE',
  'T8_JOURNAL_TROPHY_STONE',
  'T2_JOURNAL_TROPHY_ORE',
  'T3_JOURNAL_TROPHY_ORE',
  'T4_JOURNAL_TROPHY_ORE',
  'T5_JOURNAL_TROPHY_ORE',
  'T6_JOURNAL_TROPHY_ORE',
  'T7_JOURNAL_TROPHY_ORE',
  'T8_JOURNAL_TROPHY_ORE',
  'T2_JOURNAL_TROPHY_FIBER',
  'T3_JOURNAL_TROPHY_FIBER',
  'T4_JOURNAL_TROPHY_FIBER',
  'T5_JOURNAL_TROPHY_FIBER',
  'T6_JOURNAL_TROPHY_FIBER',
  'T7_JOURNAL_TROPHY_FIBER',
  'T8_JOURNAL_TROPHY_FIBER',
  'T2_JOURNAL_TROPHY_FISHING',
  'T3_JOURNAL_TROPHY_FISHING',
  'T4_JOURNAL_TROPHY_FISHING',
  'T5_JOURNAL_TROPHY_FISHING',
  'T6_JOURNAL_TROPHY_FISHING',
  'T7_JOURNAL_TROPHY_FISHING',
  'T8_JOURNAL_TROPHY_FISHING',
  'T2_JOURNAL_FISHING',
  'T3_JOURNAL_FISHING',
  'T4_JOURNAL_FISHING',
  'T5_JOURNAL_FISHING',
  'T6_JOURNAL_FISHING',
  'T7_JOURNAL_FISHING',
  'T8_JOURNAL_FISHING',
  'T2_2H_BOW',
  'T3_2H_BOW',
  'T4_2H_BOW',
  'T5_2H_BOW',
  'T6_2H_BOW',
  'T7_2H_BOW',
  'T8_2H_BOW',
  'T4_2H_WARBOW',
  'T5_2H_WARBOW',
  'T6_2H_WARBOW',
  'T7_2H_WARBOW',
  'T8_2H_WARBOW',
  'T4_2H_LONGBOW',
  'T5_2H_LONGBOW',
  'T6_2H_LONGBOW',
  'T7_2H_LONGBOW',
  'T8_2H_LONGBOW',
  'T4_2H_LONGBOW_UNDEAD',
  'T5_2H_LONGBOW_UNDEAD',
  'T6_2H_LONGBOW_UNDEAD',
  'T7_2H_LONGBOW_UNDEAD',
  'T8_2H_LONGBOW_UNDEAD',
  'T4_2H_BOW_HELL',
  'T5_2H_BOW_HELL',
  'T6_2H_BOW_HELL',
  'T7_2H_BOW_HELL',
  'T8_2H_BOW_HELL',
  'T4_2H_BOW_KEEPER',
  'T5_2H_BOW_KEEPER',
  'T6_2H_BOW_KEEPER',
  'T7_2H_BOW_KEEPER',
  'T8_2H_BOW_KEEPER',
  'T4_2H_BOW_AVALON',
  'T5_2H_BOW_AVALON',
  'T6_2H_BOW_AVALON',
  'T7_2H_BOW_AVALON',
  'T8_2H_BOW_AVALON',
  'T3_2H_CROSSBOW',
  'T4_2H_CROSSBOW',
  'T5_2H_CROSSBOW',
  'T6_2H_CROSSBOW',
  'T7_2H_CROSSBOW',
  'T8_2H_CROSSBOW',
  'T4_2H_CROSSBOWLARGE',
  'T5_2H_CROSSBOWLARGE',
  'T6_2H_CROSSBOWLARGE',
  'T7_2H_CROSSBOWLARGE',
  'T8_2H_CROSSBOWLARGE',
  'T4_MAIN_1HCROSSBOW',
  'T5_MAIN_1HCROSSBOW',
  'T6_MAIN_1HCROSSBOW',
  'T7_MAIN_1HCROSSBOW',
  'T8_MAIN_1HCROSSBOW',
  'T4_2H_REPEATINGCROSSBOW_UNDEAD',
  'T5_2H_REPEATINGCROSSBOW_UNDEAD',
  'T6_2H_REPEATINGCROSSBOW_UNDEAD',
  'T7_2H_REPEATINGCROSSBOW_UNDEAD',
  'T8_2H_REPEATINGCROSSBOW_UNDEAD',
  'T4_2H_DUALCROSSBOW_HELL',
  'T5_2H_DUALCROSSBOW_HELL',
  'T6_2H_DUALCROSSBOW_HELL',
  'T7_2H_DUALCROSSBOW_HELL',
  'T8_2H_DUALCROSSBOW_HELL',
  'T4_2H_CROSSBOWLARGE_MORGANA',
  'T5_2H_CROSSBOWLARGE_MORGANA',
  'T6_2H_CROSSBOWLARGE_MORGANA',
  'T7_2H_CROSSBOWLARGE_MORGANA',
  'T8_2H_CROSSBOWLARGE_MORGANA',
  'T4_2H_CROSSBOW_CANNON_AVALON',
  'T5_2H_CROSSBOW_CANNON_AVALON',
  'T6_2H_CROSSBOW_CANNON_AVALON',
  'T7_2H_CROSSBOW_CANNON_AVALON',
  'T8_2H_CROSSBOW_CANNON_AVALON',
  'T3_MAIN_CURSEDSTAFF',
  'T4_MAIN_CURSEDSTAFF',
  'T5_MAIN_CURSEDSTAFF',
  'T6_MAIN_CURSEDSTAFF',
  'T7_MAIN_CURSEDSTAFF',
  'T8_MAIN_CURSEDSTAFF',
  'T4_2H_CURSEDSTAFF',
  'T5_2H_CURSEDSTAFF',
  'T6_2H_CURSEDSTAFF',
  'T7_2H_CURSEDSTAFF',
  'T8_2H_CURSEDSTAFF',
  'T4_2H_DEMONICSTAFF',
  'T5_2H_DEMONICSTAFF',
  'T6_2H_DEMONICSTAFF',
  'T7_2H_DEMONICSTAFF',
  'T8_2H_DEMONICSTAFF',
  'T4_MAIN_CURSEDSTAFF_UNDEAD',
  'T5_MAIN_CURSEDSTAFF_UNDEAD',
  'T6_MAIN_CURSEDSTAFF_UNDEAD',
  'T7_MAIN_CURSEDSTAFF_UNDEAD',
  'T8_MAIN_CURSEDSTAFF_UNDEAD',
  'T4_2H_SKULLORB_HELL',
  'T5_2H_SKULLORB_HELL',
  'T6_2H_SKULLORB_HELL',
  'T7_2H_SKULLORB_HELL',
  'T8_2H_SKULLORB_HELL',
  'T4_2H_CURSEDSTAFF_MORGANA',
  'T5_2H_CURSEDSTAFF_MORGANA',
  'T6_2H_CURSEDSTAFF_MORGANA',
  'T7_2H_CURSEDSTAFF_MORGANA',
  'T8_2H_CURSEDSTAFF_MORGANA',
  'T4_MAIN_CURSEDSTAFF_AVALON',
  'T5_MAIN_CURSEDSTAFF_AVALON',
  'T6_MAIN_CURSEDSTAFF_AVALON',
  'T7_MAIN_CURSEDSTAFF_AVALON',
  'T8_MAIN_CURSEDSTAFF_AVALON',
  'T2_MAIN_FIRESTAFF',
  'T3_MAIN_FIRESTAFF',
  'T4_MAIN_FIRESTAFF',
  'T5_MAIN_FIRESTAFF',
  'T6_MAIN_FIRESTAFF',
  'T7_MAIN_FIRESTAFF',
  'T8_MAIN_FIRESTAFF',
  'T4_2H_FIRESTAFF',
  'T5_2H_FIRESTAFF',
  'T6_2H_FIRESTAFF',
  'T7_2H_FIRESTAFF',
  'T8_2H_FIRESTAFF',
  'T4_2H_INFERNOSTAFF',
  'T5_2H_INFERNOSTAFF',
  'T6_2H_INFERNOSTAFF',
  'T7_2H_INFERNOSTAFF',
  'T8_2H_INFERNOSTAFF',
  'T4_MAIN_FIRESTAFF_KEEPER',
  'T5_MAIN_FIRESTAFF_KEEPER',
  'T6_MAIN_FIRESTAFF_KEEPER',
  'T7_MAIN_FIRESTAFF_KEEPER',
  'T8_MAIN_FIRESTAFF_KEEPER',
  'T4_2H_FIRESTAFF_HELL',
  'T5_2H_FIRESTAFF_HELL',
  'T6_2H_FIRESTAFF_HELL',
  'T7_2H_FIRESTAFF_HELL',
  'T8_2H_FIRESTAFF_HELL',
  'T4_2H_INFERNOSTAFF_MORGANA',
  'T5_2H_INFERNOSTAFF_MORGANA',
  'T6_2H_INFERNOSTAFF_MORGANA',
  'T7_2H_INFERNOSTAFF_MORGANA',
  'T8_2H_INFERNOSTAFF_MORGANA',
  'T4_2H_FIRE_RINGPAIR_AVALON',
  'T5_2H_FIRE_RINGPAIR_AVALON',
  'T6_2H_FIRE_RINGPAIR_AVALON',
  'T7_2H_FIRE_RINGPAIR_AVALON',
  'T8_2H_FIRE_RINGPAIR_AVALON',
  'T3_MAIN_FROSTSTAFF',
  'T4_MAIN_FROSTSTAFF',
  'T5_MAIN_FROSTSTAFF',
  'T6_MAIN_FROSTSTAFF',
  'T7_MAIN_FROSTSTAFF',
  'T8_MAIN_FROSTSTAFF',
  'T4_2H_FROSTSTAFF',
  'T5_2H_FROSTSTAFF',
  'T6_2H_FROSTSTAFF',
  'T7_2H_FROSTSTAFF',
  'T8_2H_FROSTSTAFF',
  'T4_2H_GLACIALSTAFF',
  'T5_2H_GLACIALSTAFF',
  'T6_2H_GLACIALSTAFF',
  'T7_2H_GLACIALSTAFF',
  'T8_2H_GLACIALSTAFF',
  'T4_MAIN_FROSTSTAFF_KEEPER',
  'T5_MAIN_FROSTSTAFF_KEEPER',
  'T6_MAIN_FROSTSTAFF_KEEPER',
  'T7_MAIN_FROSTSTAFF_KEEPER',
  'T8_MAIN_FROSTSTAFF_KEEPER',
  'T4_2H_ICEGAUNTLETS_HELL',
  'T5_2H_ICEGAUNTLETS_HELL',
  'T6_2H_ICEGAUNTLETS_HELL',
  'T7_2H_ICEGAUNTLETS_HELL',
  'T8_2H_ICEGAUNTLETS_HELL',
  'T4_2H_ICECRYSTAL_UNDEAD',
  'T5_2H_ICECRYSTAL_UNDEAD',
  'T6_2H_ICECRYSTAL_UNDEAD',
  'T7_2H_ICECRYSTAL_UNDEAD',
  'T8_2H_ICECRYSTAL_UNDEAD',
  'T4_MAIN_FROSTSTAFF_AVALON',
  'T5_MAIN_FROSTSTAFF_AVALON',
  'T6_MAIN_FROSTSTAFF_AVALON',
  'T7_MAIN_FROSTSTAFF_AVALON',
  'T8_MAIN_FROSTSTAFF_AVALON',
  'T4_2H_FROSTSTAFF_CRYSTAL',
  'T5_2H_FROSTSTAFF_CRYSTAL',
  'T6_2H_FROSTSTAFF_CRYSTAL',
  'T7_2H_FROSTSTAFF_CRYSTAL',
  'T8_2H_FROSTSTAFF_CRYSTAL',
  'T3_MAIN_ARCANESTAFF',
  'T4_MAIN_ARCANESTAFF',
  'T5_MAIN_ARCANESTAFF',
  'T6_MAIN_ARCANESTAFF',
  'T7_MAIN_ARCANESTAFF',
  'T8_MAIN_ARCANESTAFF',
  'T4_2H_ARCANESTAFF',
  'T5_2H_ARCANESTAFF',
  'T6_2H_ARCANESTAFF',
  'T7_2H_ARCANESTAFF',
  'T8_2H_ARCANESTAFF',
  'T4_2H_ENIGMATICSTAFF',
  'T5_2H_ENIGMATICSTAFF',
  'T6_2H_ENIGMATICSTAFF',
  'T7_2H_ENIGMATICSTAFF',
  'T8_2H_ENIGMATICSTAFF',
  'T4_MAIN_ARCANESTAFF_UNDEAD',
  'T5_MAIN_ARCANESTAFF_UNDEAD',
  'T6_MAIN_ARCANESTAFF_UNDEAD',
  'T7_MAIN_ARCANESTAFF_UNDEAD',
  'T8_MAIN_ARCANESTAFF_UNDEAD',
  'T4_2H_ARCANESTAFF_HELL',
  'T5_2H_ARCANESTAFF_HELL',
  'T6_2H_ARCANESTAFF_HELL',
  'T7_2H_ARCANESTAFF_HELL',
  'T8_2H_ARCANESTAFF_HELL',
  'T4_2H_ENIGMATICORB_MORGANA',
  'T5_2H_ENIGMATICORB_MORGANA',
  'T6_2H_ENIGMATICORB_MORGANA',
  'T7_2H_ENIGMATICORB_MORGANA',
  'T8_2H_ENIGMATICORB_MORGANA',
  'T4_2H_ARCANE_RINGPAIR_AVALON',
  'T5_2H_ARCANE_RINGPAIR_AVALON',
  'T6_2H_ARCANE_RINGPAIR_AVALON',
  'T7_2H_ARCANE_RINGPAIR_AVALON',
  'T8_2H_ARCANE_RINGPAIR_AVALON',
  'T4_2H_ARCANESTAFF_CRYSTAL',
  'T5_2H_ARCANESTAFF_CRYSTAL',
  'T6_2H_ARCANESTAFF_CRYSTAL',
  'T7_2H_ARCANESTAFF_CRYSTAL',
  'T8_2H_ARCANESTAFF_CRYSTAL',
  'T3_MAIN_HOLYSTAFF',
  'T4_MAIN_HOLYSTAFF',
  'T5_MAIN_HOLYSTAFF',
  'T6_MAIN_HOLYSTAFF',
  'T7_MAIN_HOLYSTAFF',
  'T8_MAIN_HOLYSTAFF',
  'T4_2H_HOLYSTAFF',
  'T5_2H_HOLYSTAFF',
  'T6_2H_HOLYSTAFF',
  'T7_2H_HOLYSTAFF',
  'T8_2H_HOLYSTAFF',
  'T4_2H_DIVINESTAFF',
  'T5_2H_DIVINESTAFF',
  'T6_2H_DIVINESTAFF',
  'T7_2H_DIVINESTAFF',
  'T8_2H_DIVINESTAFF',
  'T4_MAIN_HOLYSTAFF_MORGANA',
  'T5_MAIN_HOLYSTAFF_MORGANA',
  'T6_MAIN_HOLYSTAFF_MORGANA',
  'T7_MAIN_HOLYSTAFF_MORGANA',
  'T8_MAIN_HOLYSTAFF_MORGANA',
  'T4_2H_HOLYSTAFF_HELL',
  'T5_2H_HOLYSTAFF_HELL',
  'T6_2H_HOLYSTAFF_HELL',
  'T7_2H_HOLYSTAFF_HELL',
  'T8_2H_HOLYSTAFF_HELL',
  'T4_2H_HOLYSTAFF_UNDEAD',
  'T5_2H_HOLYSTAFF_UNDEAD',
  'T6_2H_HOLYSTAFF_UNDEAD',
  'T7_2H_HOLYSTAFF_UNDEAD',
  'T8_2H_HOLYSTAFF_UNDEAD',
  'T4_MAIN_HOLYSTAFF_AVALON',
  'T5_MAIN_HOLYSTAFF_AVALON',
  'T6_MAIN_HOLYSTAFF_AVALON',
  'T7_MAIN_HOLYSTAFF_AVALON',
  'T8_MAIN_HOLYSTAFF_AVALON',
  'T4_2H_HOLYSTAFF_CRYSTAL',
  'T5_2H_HOLYSTAFF_CRYSTAL',
  'T6_2H_HOLYSTAFF_CRYSTAL',
  'T7_2H_HOLYSTAFF_CRYSTAL',
  'T8_2H_HOLYSTAFF_CRYSTAL',
  'T3_MAIN_NATURESTAFF',
  'T4_MAIN_NATURESTAFF',
  'T5_MAIN_NATURESTAFF',
  'T6_MAIN_NATURESTAFF',
  'T7_MAIN_NATURESTAFF',
  'T8_MAIN_NATURESTAFF',
  'T4_2H_NATURESTAFF',
  'T5_2H_NATURESTAFF',
  'T6_2H_NATURESTAFF',
  'T7_2H_NATURESTAFF',
  'T8_2H_NATURESTAFF',
  'T4_2H_WILDSTAFF',
  'T5_2H_WILDSTAFF',
  'T6_2H_WILDSTAFF',
  'T7_2H_WILDSTAFF',
  'T8_2H_WILDSTAFF',
  'T4_MAIN_NATURESTAFF_KEEPER',
  'T5_MAIN_NATURESTAFF_KEEPER',
  'T6_MAIN_NATURESTAFF_KEEPER',
  'T7_MAIN_NATURESTAFF_KEEPER',
  'T8_MAIN_NATURESTAFF_KEEPER',
  'T4_2H_NATURESTAFF_HELL',
  'T5_2H_NATURESTAFF_HELL',
  'T6_2H_NATURESTAFF_HELL',
  'T7_2H_NATURESTAFF_HELL',
  'T8_2H_NATURESTAFF_HELL',
  'T4_2H_NATURESTAFF_KEEPER',
  'T5_2H_NATURESTAFF_KEEPER',
  'T6_2H_NATURESTAFF_KEEPER',
  'T7_2H_NATURESTAFF_KEEPER',
  'T8_2H_NATURESTAFF_KEEPER',
  'T4_MAIN_NATURESTAFF_AVALON',
  'T5_MAIN_NATURESTAFF_AVALON',
  'T6_MAIN_NATURESTAFF_AVALON',
  'T7_MAIN_NATURESTAFF_AVALON',
  'T8_MAIN_NATURESTAFF_AVALON',
  'T3_MAIN_DAGGER',
  'T4_MAIN_DAGGER',
  'T5_MAIN_DAGGER',
  'T6_MAIN_DAGGER',
  'T7_MAIN_DAGGER',
  'T8_MAIN_DAGGER',
  'T4_2H_DAGGERPAIR',
  'T5_2H_DAGGERPAIR',
  'T6_2H_DAGGERPAIR',
  'T7_2H_DAGGERPAIR',
  'T8_2H_DAGGERPAIR',
  'T4_2H_CLAWPAIR',
  'T5_2H_CLAWPAIR',
  'T6_2H_CLAWPAIR',
  'T7_2H_CLAWPAIR',
  'T8_2H_CLAWPAIR',
  'T4_MAIN_RAPIER_MORGANA',
  'T5_MAIN_RAPIER_MORGANA',
  'T6_MAIN_RAPIER_MORGANA',
  'T7_MAIN_RAPIER_MORGANA',
  'T8_MAIN_RAPIER_MORGANA',
  'T4_MAIN_DAGGER_HELL',
  'T5_MAIN_DAGGER_HELL',
  'T6_MAIN_DAGGER_HELL',
  'T7_MAIN_DAGGER_HELL',
  'T8_MAIN_DAGGER_HELL',
  'T4_2H_IRONGAUNTLETS_HELL',
  'T5_2H_IRONGAUNTLETS_HELL',
  'T6_2H_IRONGAUNTLETS_HELL',
  'T7_2H_IRONGAUNTLETS_HELL',
  'T8_2H_IRONGAUNTLETS_HELL',
  'T4_2H_DUALSICKLE_UNDEAD',
  'T5_2H_DUALSICKLE_UNDEAD',
  'T6_2H_DUALSICKLE_UNDEAD',
  'T7_2H_DUALSICKLE_UNDEAD',
  'T8_2H_DUALSICKLE_UNDEAD',
  'T4_2H_DAGGER_KATAR_AVALON',
  'T5_2H_DAGGER_KATAR_AVALON',
  'T6_2H_DAGGER_KATAR_AVALON',
  'T7_2H_DAGGER_KATAR_AVALON',
  'T8_2H_DAGGER_KATAR_AVALON',
  'T4_2H_DAGGERPAIR_CRYSTAL',
  'T5_2H_DAGGERPAIR_CRYSTAL',
  'T6_2H_DAGGERPAIR_CRYSTAL',
  'T7_2H_DAGGERPAIR_CRYSTAL',
  'T8_2H_DAGGERPAIR_CRYSTAL',
  'T3_MAIN_SPEAR',
  'T4_MAIN_SPEAR',
  'T5_MAIN_SPEAR',
  'T6_MAIN_SPEAR',
  'T7_MAIN_SPEAR',
  'T8_MAIN_SPEAR',
  'T4_2H_SPEAR',
  'T5_2H_SPEAR',
  'T6_2H_SPEAR',
  'T7_2H_SPEAR',
  'T8_2H_SPEAR',
  'T4_2H_GLAIVE',
  'T5_2H_GLAIVE',
  'T6_2H_GLAIVE',
  'T7_2H_GLAIVE',
  'T8_2H_GLAIVE',
  'T4_MAIN_SPEAR_KEEPER',
  'T5_MAIN_SPEAR_KEEPER',
  'T6_MAIN_SPEAR_KEEPER',
  'T7_MAIN_SPEAR_KEEPER',
  'T8_MAIN_SPEAR_KEEPER',
  'T4_2H_HARPOON_HELL',
  'T5_2H_HARPOON_HELL',
  'T6_2H_HARPOON_HELL',
  'T7_2H_HARPOON_HELL',
  'T8_2H_HARPOON_HELL',
  'T4_2H_TRIDENT_UNDEAD',
  'T5_2H_TRIDENT_UNDEAD',
  'T6_2H_TRIDENT_UNDEAD',
  'T7_2H_TRIDENT_UNDEAD',
  'T8_2H_TRIDENT_UNDEAD',
  'T4_MAIN_SPEAR_LANCE_AVALON',
  'T5_MAIN_SPEAR_LANCE_AVALON',
  'T6_MAIN_SPEAR_LANCE_AVALON',
  'T7_MAIN_SPEAR_LANCE_AVALON',
  'T8_MAIN_SPEAR_LANCE_AVALON',
  'T4_2H_GLAIVE_CRYSTAL',
  'T5_2H_GLAIVE_CRYSTAL',
  'T6_2H_GLAIVE_CRYSTAL',
  'T7_2H_GLAIVE_CRYSTAL',
  'T8_2H_GLAIVE_CRYSTAL',
  'T3_MAIN_AXE',
  'T4_MAIN_AXE',
  'T5_MAIN_AXE',
  'T6_MAIN_AXE',
  'T7_MAIN_AXE',
  'T8_MAIN_AXE',
  'T4_2H_AXE',
  'T5_2H_AXE',
  'T6_2H_AXE',
  'T7_2H_AXE',
  'T8_2H_AXE',
  'T4_2H_HALBERD',
  'T5_2H_HALBERD',
  'T6_2H_HALBERD',
  'T7_2H_HALBERD',
  'T8_2H_HALBERD',
  'T4_2H_HALBERD_MORGANA',
  'T5_2H_HALBERD_MORGANA',
  'T6_2H_HALBERD_MORGANA',
  'T7_2H_HALBERD_MORGANA',
  'T8_2H_HALBERD_MORGANA',
  'T4_2H_SCYTHE_HELL',
  'T5_2H_SCYTHE_HELL',
  'T6_2H_SCYTHE_HELL',
  'T7_2H_SCYTHE_HELL',
  'T8_2H_SCYTHE_HELL',
  'T4_2H_DUALAXE_KEEPER',
  'T5_2H_DUALAXE_KEEPER',
  'T6_2H_DUALAXE_KEEPER',
  'T7_2H_DUALAXE_KEEPER',
  'T8_2H_DUALAXE_KEEPER',
  'T4_2H_AXE_AVALON',
  'T5_2H_AXE_AVALON',
  'T6_2H_AXE_AVALON',
  'T7_2H_AXE_AVALON',
  'T8_2H_AXE_AVALON',
  'T4_2H_SCYTHE_CRYSTAL',
  'T5_2H_SCYTHE_CRYSTAL',
  'T6_2H_SCYTHE_CRYSTAL',
  'T7_2H_SCYTHE_CRYSTAL',
  'T8_2H_SCYTHE_CRYSTAL',
  'T1_MAIN_SWORD',
  'T2_MAIN_SWORD',
  'T3_MAIN_SWORD',
  'T4_MAIN_SWORD',
  'T5_MAIN_SWORD',
  'T6_MAIN_SWORD',
  'T7_MAIN_SWORD',
  'T8_MAIN_SWORD',
  'T4_2H_CLAYMORE',
  'T5_2H_CLAYMORE',
  'T6_2H_CLAYMORE',
  'T7_2H_CLAYMORE',
  'T8_2H_CLAYMORE',
  'T4_2H_DUALSWORD',
  'T5_2H_DUALSWORD',
  'T6_2H_DUALSWORD',
  'T7_2H_DUALSWORD',
  'T8_2H_DUALSWORD',
  'T4_MAIN_SCIMITAR_MORGANA',
  'T5_MAIN_SCIMITAR_MORGANA',
  'T6_MAIN_SCIMITAR_MORGANA',
  'T7_MAIN_SCIMITAR_MORGANA',
  'T8_MAIN_SCIMITAR_MORGANA',
  'T4_2H_CLEAVER_HELL',
  'T5_2H_CLEAVER_HELL',
  'T6_2H_CLEAVER_HELL',
  'T7_2H_CLEAVER_HELL',
  'T8_2H_CLEAVER_HELL',
  'T4_2H_DUALSCIMITAR_UNDEAD',
  'T5_2H_DUALSCIMITAR_UNDEAD',
  'T6_2H_DUALSCIMITAR_UNDEAD',
  'T7_2H_DUALSCIMITAR_UNDEAD',
  'T8_2H_DUALSCIMITAR_UNDEAD',
  'T4_2H_CLAYMORE_AVALON',
  'T5_2H_CLAYMORE_AVALON',
  'T6_2H_CLAYMORE_AVALON',
  'T7_2H_CLAYMORE_AVALON',
  'T8_2H_CLAYMORE_AVALON',
  'T4_MAIN_SWORD_CRYSTAL',
  'T5_MAIN_SWORD_CRYSTAL',
  'T6_MAIN_SWORD_CRYSTAL',
  'T7_MAIN_SWORD_CRYSTAL',
  'T8_MAIN_SWORD_CRYSTAL',
  'T3_2H_QUARTERSTAFF',
  'T4_2H_QUARTERSTAFF',
  'T5_2H_QUARTERSTAFF',
  'T6_2H_QUARTERSTAFF',
  'T7_2H_QUARTERSTAFF',
  'T8_2H_QUARTERSTAFF',
  'T4_2H_IRONCLADEDSTAFF',
  'T5_2H_IRONCLADEDSTAFF',
  'T6_2H_IRONCLADEDSTAFF',
  'T7_2H_IRONCLADEDSTAFF',
  'T8_2H_IRONCLADEDSTAFF',
  'T4_2H_DOUBLEBLADEDSTAFF',
  'T5_2H_DOUBLEBLADEDSTAFF',
  'T6_2H_DOUBLEBLADEDSTAFF',
  'T7_2H_DOUBLEBLADEDSTAFF',
  'T8_2H_DOUBLEBLADEDSTAFF',
  'T4_2H_COMBATSTAFF_MORGANA',
  'T5_2H_COMBATSTAFF_MORGANA',
  'T6_2H_COMBATSTAFF_MORGANA',
  'T7_2H_COMBATSTAFF_MORGANA',
  'T8_2H_COMBATSTAFF_MORGANA',
  'T4_2H_TWINSCYTHE_HELL',
  'T5_2H_TWINSCYTHE_HELL',
  'T6_2H_TWINSCYTHE_HELL',
  'T7_2H_TWINSCYTHE_HELL',
  'T8_2H_TWINSCYTHE_HELL',
  'T4_2H_ROCKSTAFF_KEEPER',
  'T5_2H_ROCKSTAFF_KEEPER',
  'T6_2H_ROCKSTAFF_KEEPER',
  'T7_2H_ROCKSTAFF_KEEPER',
  'T8_2H_ROCKSTAFF_KEEPER',
  'T4_2H_QUARTERSTAFF_AVALON',
  'T5_2H_QUARTERSTAFF_AVALON',
  'T6_2H_QUARTERSTAFF_AVALON',
  'T7_2H_QUARTERSTAFF_AVALON',
  'T8_2H_QUARTERSTAFF_AVALON',
  'T4_2H_DOUBLEBLADEDSTAFF_CRYSTAL',
  'T5_2H_DOUBLEBLADEDSTAFF_CRYSTAL',
  'T6_2H_DOUBLEBLADEDSTAFF_CRYSTAL',
  'T7_2H_DOUBLEBLADEDSTAFF_CRYSTAL',
  'T8_2H_DOUBLEBLADEDSTAFF_CRYSTAL',
  'T3_MAIN_HAMMER',
  'T4_MAIN_HAMMER',
  'T5_MAIN_HAMMER',
  'T6_MAIN_HAMMER',
  'T7_MAIN_HAMMER',
  'T8_MAIN_HAMMER',
  'T4_2H_POLEHAMMER',
  'T5_2H_POLEHAMMER',
  'T6_2H_POLEHAMMER',
  'T7_2H_POLEHAMMER',
  'T8_2H_POLEHAMMER',
  'T4_2H_HAMMER',
  'T5_2H_HAMMER',
  'T6_2H_HAMMER',
  'T7_2H_HAMMER',
  'T8_2H_HAMMER',
  'T4_2H_HAMMER_UNDEAD',
  'T5_2H_HAMMER_UNDEAD',
  'T6_2H_HAMMER_UNDEAD',
  'T7_2H_HAMMER_UNDEAD',
  'T8_2H_HAMMER_UNDEAD',
  'T4_2H_DUALHAMMER_HELL',
  'T5_2H_DUALHAMMER_HELL',
  'T6_2H_DUALHAMMER_HELL',
  'T7_2H_DUALHAMMER_HELL',
  'T8_2H_DUALHAMMER_HELL',
  'T4_2H_RAM_KEEPER',
  'T5_2H_RAM_KEEPER',
  'T6_2H_RAM_KEEPER',
  'T7_2H_RAM_KEEPER',
  'T8_2H_RAM_KEEPER',
  'T4_2H_HAMMER_AVALON',
  'T5_2H_HAMMER_AVALON',
  'T6_2H_HAMMER_AVALON',
  'T7_2H_HAMMER_AVALON',
  'T8_2H_HAMMER_AVALON',
  'T3_MAIN_MACE',
  'T4_MAIN_MACE',
  'T5_MAIN_MACE',
  'T6_MAIN_MACE',
  'T7_MAIN_MACE',
  'T8_MAIN_MACE',
  'T4_2H_MACE',
  'T5_2H_MACE',
  'T6_2H_MACE',
  'T7_2H_MACE',
  'T8_2H_MACE',
  'T4_2H_FLAIL',
  'T5_2H_FLAIL',
  'T6_2H_FLAIL',
  'T7_2H_FLAIL',
  'T8_2H_FLAIL',
  'T4_MAIN_ROCKMACE_KEEPER',
  'T5_MAIN_ROCKMACE_KEEPER',
  'T6_MAIN_ROCKMACE_KEEPER',
  'T7_MAIN_ROCKMACE_KEEPER',
  'T8_MAIN_ROCKMACE_KEEPER',
  'T4_MAIN_MACE_HELL',
  'T5_MAIN_MACE_HELL',
  'T6_MAIN_MACE_HELL',
  'T7_MAIN_MACE_HELL',
  'T8_MAIN_MACE_HELL',
  'T4_2H_MACE_MORGANA',
  'T5_2H_MACE_MORGANA',
  'T6_2H_MACE_MORGANA',
  'T7_2H_MACE_MORGANA',
  'T8_2H_MACE_MORGANA',
  'T4_2H_DUALMACE_AVALON',
  'T5_2H_DUALMACE_AVALON',
  'T6_2H_DUALMACE_AVALON',
  'T7_2H_DUALMACE_AVALON',
  'T8_2H_DUALMACE_AVALON',
  'T4_MAIN_MACE_CRYSTAL',
  'T5_MAIN_MACE_CRYSTAL',
  'T6_MAIN_MACE_CRYSTAL',
  'T7_MAIN_MACE_CRYSTAL',
  'T8_MAIN_MACE_CRYSTAL',
  'T3_2H_KNUCKLES_SET1',
  'T4_2H_KNUCKLES_SET1',
  'T5_2H_KNUCKLES_SET1',
  'T6_2H_KNUCKLES_SET1',
  'T7_2H_KNUCKLES_SET1',
  'T8_2H_KNUCKLES_SET1',
  'T4_2H_KNUCKLES_SET2',
  'T5_2H_KNUCKLES_SET2',
  'T6_2H_KNUCKLES_SET2',
  'T7_2H_KNUCKLES_SET2',
  'T8_2H_KNUCKLES_SET2',
  'T4_2H_KNUCKLES_SET3',
  'T5_2H_KNUCKLES_SET3',
  'T6_2H_KNUCKLES_SET3',
  'T7_2H_KNUCKLES_SET3',
  'T8_2H_KNUCKLES_SET3',
  'T4_2H_KNUCKLES_KEEPER',
  'T5_2H_KNUCKLES_KEEPER',
  'T6_2H_KNUCKLES_KEEPER',
  'T7_2H_KNUCKLES_KEEPER',
  'T8_2H_KNUCKLES_KEEPER',
  'T4_2H_KNUCKLES_HELL',
  'T5_2H_KNUCKLES_HELL',
  'T6_2H_KNUCKLES_HELL',
  'T7_2H_KNUCKLES_HELL',
  'T8_2H_KNUCKLES_HELL',
  'T4_2H_KNUCKLES_MORGANA',
  'T5_2H_KNUCKLES_MORGANA',
  'T6_2H_KNUCKLES_MORGANA',
  'T7_2H_KNUCKLES_MORGANA',
  'T8_2H_KNUCKLES_MORGANA',
  'T4_2H_KNUCKLES_AVALON',
  'T5_2H_KNUCKLES_AVALON',
  'T6_2H_KNUCKLES_AVALON',
  'T7_2H_KNUCKLES_AVALON',
  'T8_2H_KNUCKLES_AVALON',
  'T3_2H_SHAPESHIFTER_SET1',
  'T4_2H_SHAPESHIFTER_SET1',
  'T5_2H_SHAPESHIFTER_SET1',
  'T6_2H_SHAPESHIFTER_SET1',
  'T7_2H_SHAPESHIFTER_SET1',
  'T8_2H_SHAPESHIFTER_SET1',
  'T4_2H_SHAPESHIFTER_SET2',
  'T5_2H_SHAPESHIFTER_SET2',
  'T6_2H_SHAPESHIFTER_SET2',
  'T7_2H_SHAPESHIFTER_SET2',
  'T8_2H_SHAPESHIFTER_SET2',
  'T4_2H_SHAPESHIFTER_SET3',
  'T5_2H_SHAPESHIFTER_SET3',
  'T6_2H_SHAPESHIFTER_SET3',
  'T7_2H_SHAPESHIFTER_SET3',
  'T8_2H_SHAPESHIFTER_SET3',
  'T4_2H_SHAPESHIFTER_MORGANA',
  'T5_2H_SHAPESHIFTER_MORGANA',
  'T6_2H_SHAPESHIFTER_MORGANA',
  'T7_2H_SHAPESHIFTER_MORGANA',
  'T8_2H_SHAPESHIFTER_MORGANA',
  'T4_2H_SHAPESHIFTER_HELL',
  'T5_2H_SHAPESHIFTER_HELL',
  'T6_2H_SHAPESHIFTER_HELL',
  'T7_2H_SHAPESHIFTER_HELL',
  'T8_2H_SHAPESHIFTER_HELL',
  'T4_2H_SHAPESHIFTER_KEEPER',
  'T5_2H_SHAPESHIFTER_KEEPER',
  'T6_2H_SHAPESHIFTER_KEEPER',
  'T7_2H_SHAPESHIFTER_KEEPER',
  'T8_2H_SHAPESHIFTER_KEEPER',
  'T4_2H_SHAPESHIFTER_AVALON',
  'T5_2H_SHAPESHIFTER_AVALON',
  'T6_2H_SHAPESHIFTER_AVALON',
  'T7_2H_SHAPESHIFTER_AVALON',
  'T8_2H_SHAPESHIFTER_AVALON',
  'UNIQUE_WEAPONMASTER_HEAD_PROTOTYPE',
  'UNIQUE_WEAPONMASTER_ARMOR_PROTOTYPE',
  'UNIQUE_WEAPONMASTER_SHOES_PROTOTYPE',
  'UNIQUE_GVGTOKEN_GENERIC',
  'T1_FACTION_FOREST_TOKEN_1',
  'T1_FACTION_HIGHLAND_TOKEN_1',
  'T1_FACTION_STEPPE_TOKEN_1',
  'T1_FACTION_MOUNTAIN_TOKEN_1',
  'T1_FACTION_SWAMP_TOKEN_1',
  'T1_FACTION_CAERLEON_TOKEN_1',
  'UNIQUE_SKILLBOOK_ADC_GENERAL_01',
  'UNIQUE_SILVERBAG_ADC_GENERAL_01',
  'T8_GVGSEASONREWARD_FAMEBUFF',
  'T7_GVGSEASONREWARD_FAMEBUFF',
  'T6_GVGSEASONREWARD_FAMEBUFF',
  'T5_GVGSEASONREWARD_FAMEBUFF',
  'T4_GVGSEASONREWARD_FAMEBUFF',
  'UNIQUE_HEAD_VANITY_DESTINY_SHARDS_05',
  'UNIQUE_HEAD_VANITY_NO_HELMET',
  'UNIQUE_HEAD_VANITY_NO_CAPE',
  'UNIQUE_HEAD_VANITY_NO_MAIN_HAND',
  'UNIQUE_HEAD_VANITY_NO_OFF_HAND',
  'UNIQUE_HEAD_VANITY_JESTER',
  'UNIQUE_ARMOR_VANITY_JESTER',
  'UNIQUE_SHOES_VANITY_JESTER',
  'UNIQUE_HEAD_VANITY_DRESS_RED',
  'UNIQUE_ARMOR_VANITY_DRESS_RED',
  'UNIQUE_SHOES_VANITY_DRESS_RED',
  'UNIQUE_HEAD_VANITY_DRESS_BLACK',
  'UNIQUE_ARMOR_VANITY_DRESS_BLACK',
  'UNIQUE_SHOES_VANITY_DRESS_BLACK',
  'UNIQUE_HEAD_VANITY_DRESS_BLUE',
  'UNIQUE_ARMOR_VANITY_DRESS_BLUE',
  'UNIQUE_SHOES_VANITY_DRESS_BLUE',
  'UNIQUE_HEAD_VANITY_RICH_NOBLE',
  'UNIQUE_ARMOR_VANITY_RICH_NOBLE',
  'UNIQUE_SHOES_VANITY_RICH_NOBLE',
  'UNIQUE_HEAD_VANITY_SANTACLAUS',
  'UNIQUE_ARMOR_VANITY_SANTACLAUS',
  'UNIQUE_SHOES_VANITY_SANTACLAUS',
  'UNIQUE_BACKPACK_VANITY_SANTACLAUS',
  'UNIQUE_HEAD_VANITY_WEDDING_DRESS',
  'UNIQUE_ARMOR_VANITY_WEDDING_DRESS',
  'UNIQUE_SHOES_VANITY_WEDDING_DRESS',
  'UNIQUE_OFF_VANITY_WEDDING_DRESS',
  'UNIQUE_HEAD_VANITY_WEDDING_TUXEDO',
  'UNIQUE_ARMOR_VANITY_WEDDING_TUXEDO',
  'UNIQUE_SHOES_VANITY_WEDDING_TUXEDO',
  'UNIQUE_CAPE_VANITY_WEDDING_TUXEDO',
  'UNIQUE_OFF_VANITY_WEDDING_TUXEDO',
  'UNIQUE_HEAD_VANITY_BARD',
  'UNIQUE_ARMOR_VANITY_BARD',
  'UNIQUE_SHOES_VANITY_BARD',
  'UNIQUE_HEAD_VANITY_PRIEST',
  'UNIQUE_ARMOR_VANITY_PRIEST',
  'UNIQUE_SHOES_VANITY_PRIEST',
  'UNIQUE_OFF_VANITY_PRIEST',
  'UNIQUE_HEAD_VANITY_INNKEEPER',
  'UNIQUE_ARMOR_VANITY_INNKEEPER',
  'UNIQUE_SHOES_VANITY_INNKEEPER',
  'UNIQUE_OFF_VANITY_INNKEEPER',
  'UNIQUE_HEAD_VANITY_PIRATE',
  'UNIQUE_ARMOR_VANITY_PIRATE',
  'UNIQUE_SHOES_VANITY_PIRATE',
  'UNIQUE_OFF_VANITY_PIRATE',
  'UNIQUE_OFF_VANITY_CHARITY_MARCH2020',
  'UNIQUE_VANITY_2H_FW_BRIDGEWATCH_BANNER',
  'UNIQUE_VANITY_2H_FW_THETFORD_BANNER',
  'UNIQUE_VANITY_2H_FW_FORTSTERLING_BANNER',
  'UNIQUE_VANITY_2H_FW_MARTLOCK_BANNER',
  'UNIQUE_VANITY_2H_FW_LYMHURST_BANNER',
  'UNIQUE_VANITY_2H_FW_CAERLEON_BANNER',
  'UNIQUE_HEAD_VANITY_ARENA_SET_01',
  'UNIQUE_ARMOR_VANITY_ARENA_SET_01',
  'UNIQUE_SHOES_VANITY_ARENA_SET_01',
  'UNIQUE_CAPE_VANITY_ARENA_SET_01',
  'UNIQUE_HEAD_VANITY_ARENA_EYEPATCH',
  'T4_CAPE_ARENA_BANNER',
  'T6_CAPE_ARENA_BANNER',
  'T8_CAPE_ARENA_BANNER',
  'UNIQUE_HEAD_VANITY_GLADIATOR_ARENA',
  'UNIQUE_ARMOR_VANITY_GLADIATOR_ARENA',
  'UNIQUE_SHOES_VANITY_GLADIATOR_ARENA',
  'UNIQUE_CAPE_VANITY_GLADIATOR_ARENA',
  'UNIQUE_HEAD_VANITY_GLADIATOR_CRYSTAL',
  'UNIQUE_ARMOR_VANITY_GLADIATOR_CRYSTAL',
  'UNIQUE_SHOES_VANITY_GLADIATOR_CRYSTAL',
  'UNIQUE_CAPE_VANITY_GLADIATOR_CRYSTAL',
  'UNIQUE_HEAD_TELLAFRIEND_BATRIDER',
  'UNIQUE_HEAD_VANITY_EASTER_WHITE',
  'UNIQUE_HEAD_VANITY_EASTER_BROWN',
  'UNIQUE_ARMOR_VANITY_EASTER',
  'UNIQUE_SHOES_VANITY_EASTER',
  'UNIQUE_BACKPACK_VANITY_EASTER',
  'UNIQUE_HEAD_VANITY_PLAGUEDOC',
  'UNIQUE_ARMOR_VANITY_PLAGUEDOC',
  'UNIQUE_SHOES_VANITY_PLAGUEDOC',
  'UNIQUE_HEAD_VANITY_SKELETON',
  'UNIQUE_ARMOR_VANITY_SKELETON',
  'UNIQUE_SHOES_VANITY_SKELETON',
  'UNIQUE_CAPE_VANITY_SKELETON',
  'UNIQUE_HEAD_VANITY_PUMPKIN_01',
  'UNIQUE_HEAD_VANITY_PUMPKIN_02',
  'UNIQUE_HEAD_VANITY_PUMPKIN_03',
  'UNIQUE_HEAD_XMAS',
  'UNIQUE_VANITY_2H_PORTALGUN_TELLAFRIEND',
  'UNIQUE_VANITY_2H_CHICKENGUN_EASTER',
  'UNIQUE_VANITY_2H_GUN_ANNIVERSARY',
  'UNIQUE_VANITY_MAIN_BEERMUG_TELLAFRIEND',
  'UNIQUE_VANITY_MAIN_HORN_TELLAFRIEND',
  'UNIQUE_VANITY_MAIN_FOXCROP_TELLAFRIEND',
  'UNIQUE_VANITY_MAIN_TRUMPET_TELLAFRIEND',
  'UNIQUE_VANITY_MAIN_BEERMUG_02_TELLAFRIEND',
  'UNIQUE_CAPE_FOUNDER_LEGENDARY',
  'UNIQUE_HEAD_FOUNDER_LEGENDARY',
  'UNIQUE_ARMOR_FOUNDER_LEGENDARY',
  'UNIQUE_SHOES_FOUNDER_LEGENDARY',
  'UNIQUE_HEAD_FOUNDER_ELITE',
  'UNIQUE_ARMOR_FOUNDER_ELITE',
  'UNIQUE_SHOES_FOUNDER_ELITE',
  'UNIQUE_CAPE_STARTERPACK_LEGENDARY',
  'UNIQUE_HEAD_STARTERPACK_LEGENDARY',
  'UNIQUE_ARMOR_STARTERPACK_LEGENDARY',
  'UNIQUE_SHOES_STARTERPACK_LEGENDARY',
  'UNIQUE_HEAD_STARTERPACK_ELITE',
  'UNIQUE_ARMOR_STARTERPACK_ELITE',
  'UNIQUE_SHOES_STARTERPACK_ELITE',
  'UNIQUE_HEAD_VANITY_SKELETON_UNDEAD',
  'UNIQUE_ARMOR_VANITY_SKELETON_UNDEAD',
  'UNIQUE_SHOES_VANITY_SKELETON_UNDEAD',
  'UNIQUE_CAPE_VANITY_SKELETON_UNDEAD',
  'UNIQUE_CAPE_TELLAFRIEND',
  'UNIQUE_CAPE_TELLAFRIEND_02',
  'UNIQUE_CAPE_TELLAFRIEND_BATRIDER',
  'UNIQUE_CAPE_TELLAFRIEND_BANNER',
  'UNIQUE_CAPE_TELLAFRIEND_BANNER_02',
  'UNIQUE_CAPE_TOAD_TELLAFRIEND',
  'UNIQUE_CAPE_BLOODHOUND_TELLAFRIEND',
  'UNIQUE_CAPE_TIGER_TELLAFRIEND',
  'UNIQUE_CAPE_RAM_TELLAFRIEND',
  'UNIQUE_CAPE_WOLF_GREY_TELLAFRIEND',
  'T6_CAPE_PLATE_UNDEAD',
  'T6_CAPE_LEATHER_UNDEAD',
  'T6_CAPE_CLOTH_UNDEAD',
  'T6_CAPE_PLATE_KEEPER',
  'T6_CAPE_LEATHER_KEEPER',
  'T6_CAPE_CLOTH_KEEPER',
  'T6_CAPE_PLATE_MORGANA',
  'T6_CAPE_LEATHER_MORGANA',
  'T6_CAPE_CLOTH_MORGANA',
  'UNIQUE_HEAD_VANITY_ROGUE',
  'UNIQUE_ARMOR_VANITY_ROGUE',
  'UNIQUE_SHOES_VANITY_ROGUE',
  'UNIQUE_CAPE_VANITY_ROGUE',
  'UNIQUE_VANITY_MAIN_POISONBOTTLE_ROGUE',
  'UNIQUE_ARMOR_VANITY_ROGUE_GUILD_COLORED',
  'UNIQUE_CAPE_VANITY_ROGUE_GUILD_COLORED',
  'UNIQUE_HEAD_VANITY_KNIGHT',
  'UNIQUE_ARMOR_VANITY_KNIGHT',
  'UNIQUE_SHOES_VANITY_KNIGHT',
  'UNIQUE_CAPE_VANITY_KNIGHT',
  'UNIQUE_VANITY_2H_BANNER_KNIGHT_GUILD_COLORED',
  'UNIQUE_HEAD_VANITY_KNIGHT_GUILD_COLORED',
  'UNIQUE_ARMOR_VANITY_KNIGHT_GUILD_COLORED',
  'UNIQUE_CAPE_VANITY_KNIGHT_GUILD_COLORED',
  'UNIQUE_HEAD_VANITY_MAGE',
  'UNIQUE_ARMOR_VANITY_MAGE',
  'UNIQUE_SHOES_VANITY_MAGE',
  'UNIQUE_CAPE_VANITY_MAGE',
  'UNIQUE_VANITY_2H_SPELLBOOK_MAGE',
  'UNIQUE_HEAD_VANITY_MAGE_GUILD_COLORED',
  'UNIQUE_ARMOR_VANITY_MAGE_GUILD_COLORED',
  'UNIQUE_CAPE_VANITY_MAGE_GUILD_COLORED',
  'UNIQUE_HEAD_VANITY_BARBARIAN',
  'UNIQUE_ARMOR_VANITY_BARBARIAN',
  'UNIQUE_SHOES_VANITY_BARBARIAN',
  'UNIQUE_CAPE_VANITY_BARBARIAN',
  'UNIQUE_HEAD_VANITY_SOULKEEPER',
  'UNIQUE_ARMOR_VANITY_SOULKEEPER',
  'UNIQUE_SHOES_VANITY_SOULKEEPER',
  'UNIQUE_CAPE_VANITY_SOULKEEPER',
  'UNIQUE_VANITY_2H_PHYLACTERY_SOULKEEPER',
  'UNIQUE_HEAD_VANITY_PALADIN',
  'UNIQUE_ARMOR_VANITY_PALADIN',
  'UNIQUE_SHOES_VANITY_PALADIN',
  'UNIQUE_CAPE_VANITY_PALADIN',
  'UNIQUE_ARMOR_VANITY_PALADIN_GUILD_COLORED',
  'UNIQUE_CAPE_VANITY_PALADIN_GUILD_COLORED',
  'UNIQUE_HEAD_VANITY_PALADIN_ANNIVERSARY',
  'UNIQUE_ARMOR_VANITY_PALADIN_ANNIVERSARY',
  'UNIQUE_SHOES_VANITY_PALADIN_ANNIVERSARY',
  'UNIQUE_CAPE_VANITY_PALADIN_ANNIVERSARY',
  'UNIQUE_HEAD_VANITY_DEMONHUNTER',
  'UNIQUE_ARMOR_VANITY_DEMONHUNTER',
  'UNIQUE_SHOES_VANITY_DEMONHUNTER',
  'UNIQUE_CAPE_VANITY_DEMONHUNTER',
  'UNIQUE_HEAD_VANITY_DRUID',
  'UNIQUE_ARMOR_VANITY_DRUID',
  'UNIQUE_SHOES_VANITY_DRUID',
  'UNIQUE_CAPE_VANITY_DRUID',
  'UNIQUE_HEAD_VANITY_DEMONICWARRIOR',
  'UNIQUE_ARMOR_VANITY_DEMONICWARRIOR',
  'UNIQUE_SHOES_VANITY_DEMONICWARRIOR',
  'UNIQUE_CAPE_VANITY_DEMONICWARRIOR',
  'UNIQUE_HEAD_VANITY_MONK',
  'UNIQUE_HEAD_VANITY_MONK_HAT',
  'UNIQUE_HEAD_VANITY_MONK_MASK',
  'UNIQUE_ARMOR_VANITY_MONK',
  'UNIQUE_SHOES_VANITY_MONK',
  'UNIQUE_CAPE_VANITY_MONK',
  'UNIQUE_HEAD_VANITY_MONK_MASK_GUILD_COLORED',
  'UNIQUE_ARMOR_VANITY_MONK_GUILD_COLORED',
  'UNIQUE_HEAD_VANITY_MARAUDER',
  'UNIQUE_ARMOR_VANITY_MARAUDER',
  'UNIQUE_SHOES_VANITY_MARAUDER',
  'UNIQUE_CAPE_VANITY_MARAUDER',
  'UNIQUE_HEAD_VANITY_FIREMAGE',
  'UNIQUE_ARMOR_VANITY_FIREMAGE',
  'UNIQUE_SHOES_VANITY_FIREMAGE',
  'UNIQUE_CAPE_VANITY_FIREMAGE',
  'UNIQUE_HEAD_VANITY_FROSTMAGE',
  'UNIQUE_ARMOR_VANITY_FROSTMAGE',
  'UNIQUE_SHOES_VANITY_FROSTMAGE',
  'UNIQUE_CAPE_VANITY_FROSTMAGE',
  'UNIQUE_HEAD_VANITY_PRIEST_HOLY',
  'UNIQUE_ARMOR_VANITY_PRIEST_HOLY',
  'UNIQUE_SHOES_VANITY_PRIEST_HOLY',
  'UNIQUE_CAPE_VANITY_PRIEST_HOLY',
  'UNIQUE_HEAD_VANITY_RANGER_HOOD',
  'UNIQUE_HEAD_VANITY_RANGER_MASK',
  'UNIQUE_ARMOR_VANITY_RANGER',
  'UNIQUE_SHOES_VANITY_RANGER',
  'UNIQUE_CAPE_VANITY_RANGER',
  'UNIQUE_HEAD_VANITY_BATTLEMAGE',
  'UNIQUE_ARMOR_VANITY_BATTLEMAGE',
  'UNIQUE_SHOES_VANITY_BATTLEMAGE',
  'UNIQUE_CAPE_VANITY_BATTLEMAGE',
  'UNIQUE_HEAD_VANITY_ZEALOT',
  'UNIQUE_ARMOR_VANITY_ZEALOT',
  'UNIQUE_SHOES_VANITY_ZEALOT',
  'UNIQUE_CAPE_VANITY_ZEALOT',
  'UNIQUE_HEAD_VANITY_TITAN',
  'UNIQUE_ARMOR_VANITY_TITAN',
  'UNIQUE_SHOES_VANITY_TITAN',
  'UNIQUE_CAPE_VANITY_TITAN',
  'UNIQUE_HEAD_VANITY_ASSASSIN',
  'UNIQUE_ARMOR_VANITY_ASSASSIN',
  'UNIQUE_SHOES_VANITY_ASSASSIN',
  'UNIQUE_CAPE_VANITY_ASSASSIN',
  'UNIQUE_HEAD_VANITY_TRACKER',
  'UNIQUE_ARMOR_VANITY_TRACKER',
  'UNIQUE_SHOES_VANITY_TRACKER',
  'UNIQUE_CAPE_VANITY_TRACKER',
  'UNIQUE_HEAD_VANITY_ALCHEMIST',
  'UNIQUE_ARMOR_VANITY_ALCHEMIST',
  'UNIQUE_SHOES_VANITY_ALCHEMIST',
  'UNIQUE_CAPE_VANITY_ALCHEMIST',
  'UNIQUE_HEAD_VANITY_FROSTKNIGHT',
  'UNIQUE_ARMOR_VANITY_FROSTKNIGHT',
  'UNIQUE_SHOES_VANITY_FROSTKNIGHT',
  'UNIQUE_CAPE_VANITY_FROSTKNIGHT',
  'UNIQUE_HEAD_VANITY_DUELIST',
  'UNIQUE_ARMOR_VANITY_DUELIST',
  'UNIQUE_SHOES_VANITY_DUELIST',
  'UNIQUE_CAPE_VANITY_DUELIST',
  'UNIQUE_HEAD_VANITY_DUELIST_01',
  'UNIQUE_ARMOR_VANITY_DUELIST_01',
  'UNIQUE_SHOES_VANITY_DUELIST_01',
  'UNIQUE_CAPE_VANITY_DUELIST_01',
  'UNIQUE_BACKPACK_VANITY_KICKSTART',
  'UNIQUE_HEAD_VANITY_KICKSTART_2024_UPGRADE',
  'UNIQUE_ARMOR_VANITY_KICKSTART_2024_UPGRADE',
  'UNIQUE_SHOES_VANITY_KICKSTART_2024_UPGRADE',
  'UNIQUE_CAPE_VANITY_KICKSTART_2024_UPGRADE',
  'UNIQUE_HEAD_VANITY_KICKSTART_2024',
  'UNIQUE_ARMOR_VANITY_KICKSTART_2024',
  'UNIQUE_SHOES_VANITY_KICKSTART_2024',
  'UNIQUE_CAPE_VANITY_KICKSTART_2024',
  'UNIQUE_HEAD_VANITY_DRESS_GREEN',
  'UNIQUE_ARMOR_VANITY_DRESS_GREEN',
  'UNIQUE_SHOES_VANITY_DRESS_GREEN',
  'UNIQUE_HEAD_VANITY_ENTERTAINER',
  'UNIQUE_ARMOR_VANITY_ENTERTAINER',
  'UNIQUE_SHOES_VANITY_ENTERTAINER',
  'UNIQUE_HEAD_VANITY_NIGHTGOWN',
  'UNIQUE_CAPE_VANITY_HYENA',
  'UNIQUE_HEAD_VANITY_PIRATE_GREEN',
  'UNIQUE_ARMOR_VANITY_PIRATE_GREEN',
  'UNIQUE_SHOES_VANITY_PIRATE_GREEN',
  'UNIQUE_OFF_VANITY_PIRATE_RED',
  'UNIQUE_OFF_VANITY_PIRATE_GREEN',
  'UNIQUE_OFF_VANITY_PIRATE_COCKATOO',
  'UNIQUE_HEAD_VANITY_PRIEST_RED',
  'UNIQUE_ARMOR_VANITY_PRIEST_RED',
  'UNIQUE_SHOES_VANITY_PRIEST_RED',
  'UNIQUE_OFF_VANITY_PRIEST_RED',
  'UNIQUE_CAPE_VANITY_SNOWLEOPARD',
  'UNIQUE_HEAD_VANITY_RICH_NOBLE_PURPLE',
  'UNIQUE_ARMOR_VANITY_RICH_NOBLE_PURPLE',
  'UNIQUE_SHOES_VANITY_RICH_NOBLE_PURPLE',
  'UNIQUE_HEAD_VANITY_NIGHTGOWN_BLACK',
  'UNIQUE_HEAD_VANITY_NIGHTGOWN_BELL',
  'UNIQUE_HEAD_VANITY_BARD_BLUE',
  'UNIQUE_ARMOR_VANITY_BARD_BLUE',
  'UNIQUE_SHOES_VANITY_BARD_BLUE',
  'UNIQUE_VANITY_MAIN_DICE_SIXSIDED_AJ',
  'UNIQUE_VANITY_MAIN_DICE_TWENTYSIDED_AJ',
  'UNIQUE_VANITY_2H_MULTITOOL_AJ',
  'UNIQUE_VANITY_2H_MULTITOOL_OVERCHARGED_AJ',
  'UNIQUE_ARMOR_VANITY_WEALTHY_NOBLE_AJ',
  'UNIQUE_ARMOR_VANITY_VERY_WEALTHY_NOBLE_AJ',
  'UNIQUE_HEAD_VANITY_FARMER_AJ',
  'UNIQUE_HEAD_VANITY_FARMER_COZY_AJ',
  'UNIQUE_HEAD_VANITY_DESTINY_STONES_AJ',
  'UNIQUE_HEAD_VANITY_DESTINY_STONES_ADVANCED_AJ',
  'UNIQUE_VANITY_2H_LUTE_AJ',
  'UNIQUE_VANITY_2H_LUTE_MAGNIFICENT_AJ',
  'UNIQUE_HEAD_VANITY_SHADOW_HELMET_ORANGE_AJ',
  'UNIQUE_HEAD_VANITY_SHADOW_HELMET_RED_AJ',
  'UNIQUE_VANITY_2H_BANNER_GUIDON_AJ',
  'UNIQUE_VANITY_2H_BANNER_GUIDON_GOLDEN_AJ',
  'UNIQUE_VANITY_2H_SKULL_UNDEAD_AJ',
  'UNIQUE_VANITY_2H_SKULL_DEMON_GOLDEN_AJ',
  'T4_DEBUG_HEAD_HIDDEN',
  'T4_DEBUG_ARMOR_HIDDEN',
  'T4_DEBUG_CAPE_HIDDEN',
  'T4_DEBUG_SHOES_HIDDEN',
  'T4_DEBUG_MAINHAND_HIDDEN',
  'T4_DEBUG_OFFHAND_HIDDEN',
  'T4_SIEGE_BANNER',
  'T5_SIEGE_BANNER',
  'T6_SIEGE_BANNER',
  'T7_SIEGE_BANNER',
  'T8_SIEGE_BANNER',
  'T2_JOURNAL_WOOD_EMPTY',
  'T2_JOURNAL_WOOD_FULL',
  'T3_JOURNAL_WOOD_EMPTY',
  'T3_JOURNAL_WOOD_FULL',
  'T4_JOURNAL_WOOD_EMPTY',
  'T4_JOURNAL_WOOD_FULL',
  'T5_JOURNAL_WOOD_EMPTY',
  'T5_JOURNAL_WOOD_FULL',
  'T6_JOURNAL_WOOD_EMPTY',
  'T6_JOURNAL_WOOD_FULL',
  'T7_JOURNAL_WOOD_EMPTY',
  'T7_JOURNAL_WOOD_FULL',
  'T8_JOURNAL_WOOD_EMPTY',
  'T8_JOURNAL_WOOD_FULL',
  'T2_JOURNAL_STONE_EMPTY',
  'T2_JOURNAL_STONE_FULL',
  'T3_JOURNAL_STONE_EMPTY',
  'T3_JOURNAL_STONE_FULL',
  'T4_JOURNAL_STONE_EMPTY',
  'T4_JOURNAL_STONE_FULL',
  'T5_JOURNAL_STONE_EMPTY',
  'T5_JOURNAL_STONE_FULL',
  'T6_JOURNAL_STONE_EMPTY',
  'T6_JOURNAL_STONE_FULL',
  'T7_JOURNAL_STONE_EMPTY',
  'T7_JOURNAL_STONE_FULL',
  'T8_JOURNAL_STONE_EMPTY',
  'T8_JOURNAL_STONE_FULL',
  'T2_JOURNAL_ORE_EMPTY',
  'T2_JOURNAL_ORE_FULL',
  'T3_JOURNAL_ORE_EMPTY',
  'T3_JOURNAL_ORE_FULL',
  'T4_JOURNAL_ORE_EMPTY',
  'T4_JOURNAL_ORE_FULL',
  'T5_JOURNAL_ORE_EMPTY',
  'T5_JOURNAL_ORE_FULL',
  'T6_JOURNAL_ORE_EMPTY',
  'T6_JOURNAL_ORE_FULL',
  'T7_JOURNAL_ORE_EMPTY',
  'T7_JOURNAL_ORE_FULL',
  'T8_JOURNAL_ORE_EMPTY',
  'T8_JOURNAL_ORE_FULL',
  'T2_JOURNAL_FIBER_EMPTY',
  'T2_JOURNAL_FIBER_FULL',
  'T3_JOURNAL_FIBER_EMPTY',
  'T3_JOURNAL_FIBER_FULL',
  'T4_JOURNAL_FIBER_EMPTY',
  'T4_JOURNAL_FIBER_FULL',
  'T5_JOURNAL_FIBER_EMPTY',
  'T5_JOURNAL_FIBER_FULL',
  'T6_JOURNAL_FIBER_EMPTY',
  'T6_JOURNAL_FIBER_FULL',
  'T7_JOURNAL_FIBER_EMPTY',
  'T7_JOURNAL_FIBER_FULL',
  'T8_JOURNAL_FIBER_EMPTY',
  'T8_JOURNAL_FIBER_FULL',
  'T2_JOURNAL_HIDE_EMPTY',
  'T2_JOURNAL_HIDE_FULL',
  'T3_JOURNAL_HIDE_EMPTY',
  'T3_JOURNAL_HIDE_FULL',
  'T4_JOURNAL_HIDE_EMPTY',
  'T4_JOURNAL_HIDE_FULL',
  'T5_JOURNAL_HIDE_EMPTY',
  'T5_JOURNAL_HIDE_FULL',
  'T6_JOURNAL_HIDE_EMPTY',
  'T6_JOURNAL_HIDE_FULL',
  'T7_JOURNAL_HIDE_EMPTY',
  'T7_JOURNAL_HIDE_FULL',
  'T8_JOURNAL_HIDE_EMPTY',
  'T8_JOURNAL_HIDE_FULL',
  'T2_JOURNAL_WARRIOR_EMPTY',
  'T2_JOURNAL_WARRIOR_FULL',
  'T3_JOURNAL_WARRIOR_EMPTY',
  'T3_JOURNAL_WARRIOR_FULL',
  'T4_JOURNAL_WARRIOR_EMPTY',
  'T4_JOURNAL_WARRIOR_FULL',
  'T5_JOURNAL_WARRIOR_EMPTY',
  'T5_JOURNAL_WARRIOR_FULL',
  'T6_JOURNAL_WARRIOR_EMPTY',
  'T6_JOURNAL_WARRIOR_FULL',
  'T7_JOURNAL_WARRIOR_EMPTY',
  'T7_JOURNAL_WARRIOR_FULL',
  'T8_JOURNAL_WARRIOR_EMPTY',
  'T8_JOURNAL_WARRIOR_FULL',
  'T2_JOURNAL_HUNTER_EMPTY',
  'T2_JOURNAL_HUNTER_FULL',
  'T3_JOURNAL_HUNTER_EMPTY',
  'T3_JOURNAL_HUNTER_FULL',
  'T4_JOURNAL_HUNTER_EMPTY',
  'T4_JOURNAL_HUNTER_FULL',
  'T5_JOURNAL_HUNTER_EMPTY',
  'T5_JOURNAL_HUNTER_FULL',
  'T6_JOURNAL_HUNTER_EMPTY',
  'T6_JOURNAL_HUNTER_FULL',
  'T7_JOURNAL_HUNTER_EMPTY',
  'T7_JOURNAL_HUNTER_FULL',
  'T8_JOURNAL_HUNTER_EMPTY',
  'T8_JOURNAL_HUNTER_FULL',
  'T2_JOURNAL_MAGE_EMPTY',
  'T2_JOURNAL_MAGE_FULL',
  'T3_JOURNAL_MAGE_EMPTY',
  'T3_JOURNAL_MAGE_FULL',
  'T4_JOURNAL_MAGE_EMPTY',
  'T4_JOURNAL_MAGE_FULL',
  'T5_JOURNAL_MAGE_EMPTY',
  'T5_JOURNAL_MAGE_FULL',
  'T6_JOURNAL_MAGE_EMPTY',
  'T6_JOURNAL_MAGE_FULL',
  'T7_JOURNAL_MAGE_EMPTY',
  'T7_JOURNAL_MAGE_FULL',
  'T8_JOURNAL_MAGE_EMPTY',
  'T8_JOURNAL_MAGE_FULL',
  'T2_JOURNAL_TOOLMAKER_EMPTY',
  'T2_JOURNAL_TOOLMAKER_FULL',
  'T3_JOURNAL_TOOLMAKER_EMPTY',
  'T3_JOURNAL_TOOLMAKER_FULL',
  'T4_JOURNAL_TOOLMAKER_EMPTY',
  'T4_JOURNAL_TOOLMAKER_FULL',
  'T5_JOURNAL_TOOLMAKER_EMPTY',
  'T5_JOURNAL_TOOLMAKER_FULL',
  'T6_JOURNAL_TOOLMAKER_EMPTY',
  'T6_JOURNAL_TOOLMAKER_FULL',
  'T7_JOURNAL_TOOLMAKER_EMPTY',
  'T7_JOURNAL_TOOLMAKER_FULL',
  'T8_JOURNAL_TOOLMAKER_EMPTY',
  'T8_JOURNAL_TOOLMAKER_FULL',
  'T2_JOURNAL_MERCENARY_EMPTY',
  'T2_JOURNAL_MERCENARY_FULL',
  'T3_JOURNAL_MERCENARY_EMPTY',
  'T3_JOURNAL_MERCENARY_FULL',
  'T4_JOURNAL_MERCENARY_EMPTY',
  'T4_JOURNAL_MERCENARY_FULL',
  'T5_JOURNAL_MERCENARY_EMPTY',
  'T5_JOURNAL_MERCENARY_FULL',
  'T6_JOURNAL_MERCENARY_EMPTY',
  'T6_JOURNAL_MERCENARY_FULL',
  'T7_JOURNAL_MERCENARY_EMPTY',
  'T7_JOURNAL_MERCENARY_FULL',
  'T8_JOURNAL_MERCENARY_EMPTY',
  'T8_JOURNAL_MERCENARY_FULL',
  'T2_JOURNAL_TROPHY_GENERAL_EMPTY',
  'T2_JOURNAL_TROPHY_GENERAL_FULL',
  'T3_JOURNAL_TROPHY_GENERAL_EMPTY',
  'T3_JOURNAL_TROPHY_GENERAL_FULL',
  'T4_JOURNAL_TROPHY_GENERAL_EMPTY',
  'T4_JOURNAL_TROPHY_GENERAL_FULL',
  'T5_JOURNAL_TROPHY_GENERAL_EMPTY',
  'T5_JOURNAL_TROPHY_GENERAL_FULL',
  'T6_JOURNAL_TROPHY_GENERAL_EMPTY',
  'T6_JOURNAL_TROPHY_GENERAL_FULL',
  'T7_JOURNAL_TROPHY_GENERAL_EMPTY',
  'T7_JOURNAL_TROPHY_GENERAL_FULL',
  'T8_JOURNAL_TROPHY_GENERAL_EMPTY',
  'T8_JOURNAL_TROPHY_GENERAL_FULL',
  'T2_JOURNAL_TROPHY_MERCENARY_EMPTY',
  'T2_JOURNAL_TROPHY_MERCENARY_FULL',
  'T3_JOURNAL_TROPHY_MERCENARY_EMPTY',
  'T3_JOURNAL_TROPHY_MERCENARY_FULL',
  'T4_JOURNAL_TROPHY_MERCENARY_EMPTY',
  'T4_JOURNAL_TROPHY_MERCENARY_FULL',
  'T5_JOURNAL_TROPHY_MERCENARY_EMPTY',
  'T5_JOURNAL_TROPHY_MERCENARY_FULL',
  'T6_JOURNAL_TROPHY_MERCENARY_EMPTY',
  'T6_JOURNAL_TROPHY_MERCENARY_FULL',
  'T7_JOURNAL_TROPHY_MERCENARY_EMPTY',
  'T7_JOURNAL_TROPHY_MERCENARY_FULL',
  'T8_JOURNAL_TROPHY_MERCENARY_EMPTY',
  'T8_JOURNAL_TROPHY_MERCENARY_FULL',
  'T2_JOURNAL_TROPHY_HIDE_EMPTY',
  'T2_JOURNAL_TROPHY_HIDE_FULL',
  'T3_JOURNAL_TROPHY_HIDE_EMPTY',
  'T3_JOURNAL_TROPHY_HIDE_FULL',
  'T4_JOURNAL_TROPHY_HIDE_EMPTY',
  'T4_JOURNAL_TROPHY_HIDE_FULL',
  'T5_JOURNAL_TROPHY_HIDE_EMPTY',
  'T5_JOURNAL_TROPHY_HIDE_FULL',
  'T6_JOURNAL_TROPHY_HIDE_EMPTY',
  'T6_JOURNAL_TROPHY_HIDE_FULL',
  'T7_JOURNAL_TROPHY_HIDE_EMPTY',
  'T7_JOURNAL_TROPHY_HIDE_FULL',
  'T8_JOURNAL_TROPHY_HIDE_EMPTY',
  'T8_JOURNAL_TROPHY_HIDE_FULL',
  'T2_JOURNAL_TROPHY_WOOD_EMPTY',
  'T2_JOURNAL_TROPHY_WOOD_FULL',
  'T3_JOURNAL_TROPHY_WOOD_EMPTY',
  'T3_JOURNAL_TROPHY_WOOD_FULL',
  'T4_JOURNAL_TROPHY_WOOD_EMPTY',
  'T4_JOURNAL_TROPHY_WOOD_FULL',
  'T5_JOURNAL_TROPHY_WOOD_EMPTY',
  'T5_JOURNAL_TROPHY_WOOD_FULL',
  'T6_JOURNAL_TROPHY_WOOD_EMPTY',
  'T6_JOURNAL_TROPHY_WOOD_FULL',
  'T7_JOURNAL_TROPHY_WOOD_EMPTY',
  'T7_JOURNAL_TROPHY_WOOD_FULL',
  'T8_JOURNAL_TROPHY_WOOD_EMPTY',
  'T8_JOURNAL_TROPHY_WOOD_FULL',
  'T2_JOURNAL_TROPHY_STONE_EMPTY',
  'T2_JOURNAL_TROPHY_STONE_FULL',
  'T3_JOURNAL_TROPHY_STONE_EMPTY',
  'T3_JOURNAL_TROPHY_STONE_FULL',
  'T4_JOURNAL_TROPHY_STONE_EMPTY',
  'T4_JOURNAL_TROPHY_STONE_FULL',
  'T5_JOURNAL_TROPHY_STONE_EMPTY',
  'T5_JOURNAL_TROPHY_STONE_FULL',
  'T6_JOURNAL_TROPHY_STONE_EMPTY',
  'T6_JOURNAL_TROPHY_STONE_FULL',
  'T7_JOURNAL_TROPHY_STONE_EMPTY',
  'T7_JOURNAL_TROPHY_STONE_FULL',
  'T8_JOURNAL_TROPHY_STONE_EMPTY',
  'T8_JOURNAL_TROPHY_STONE_FULL',
  'T2_JOURNAL_TROPHY_ORE_EMPTY',
  'T2_JOURNAL_TROPHY_ORE_FULL',
  'T3_JOURNAL_TROPHY_ORE_EMPTY',
  'T3_JOURNAL_TROPHY_ORE_FULL',
  'T4_JOURNAL_TROPHY_ORE_EMPTY',
  'T4_JOURNAL_TROPHY_ORE_FULL',
  'T5_JOURNAL_TROPHY_ORE_EMPTY',
  'T5_JOURNAL_TROPHY_ORE_FULL',
  'T6_JOURNAL_TROPHY_ORE_EMPTY',
  'T6_JOURNAL_TROPHY_ORE_FULL',
  'T7_JOURNAL_TROPHY_ORE_EMPTY',
  'T7_JOURNAL_TROPHY_ORE_FULL',
  'T8_JOURNAL_TROPHY_ORE_EMPTY',
  'T8_JOURNAL_TROPHY_ORE_FULL',
  'T2_JOURNAL_TROPHY_FIBER_EMPTY',
  'T2_JOURNAL_TROPHY_FIBER_FULL',
  'T3_JOURNAL_TROPHY_FIBER_EMPTY',
  'T3_JOURNAL_TROPHY_FIBER_FULL',
  'T4_JOURNAL_TROPHY_FIBER_EMPTY',
  'T4_JOURNAL_TROPHY_FIBER_FULL',
  'T5_JOURNAL_TROPHY_FIBER_EMPTY',
  'T5_JOURNAL_TROPHY_FIBER_FULL',
  'T6_JOURNAL_TROPHY_FIBER_EMPTY',
  'T6_JOURNAL_TROPHY_FIBER_FULL',
  'T7_JOURNAL_TROPHY_FIBER_EMPTY',
  'T7_JOURNAL_TROPHY_FIBER_FULL',
  'T8_JOURNAL_TROPHY_FIBER_EMPTY',
  'T8_JOURNAL_TROPHY_FIBER_FULL',
  'T2_JOURNAL_TROPHY_FISHING_EMPTY',
  'T2_JOURNAL_TROPHY_FISHING_FULL',
  'T3_JOURNAL_TROPHY_FISHING_EMPTY',
  'T3_JOURNAL_TROPHY_FISHING_FULL',
  'T4_JOURNAL_TROPHY_FISHING_EMPTY',
  'T4_JOURNAL_TROPHY_FISHING_FULL',
  'T5_JOURNAL_TROPHY_FISHING_EMPTY',
  'T5_JOURNAL_TROPHY_FISHING_FULL',
  'T6_JOURNAL_TROPHY_FISHING_EMPTY',
  'T6_JOURNAL_TROPHY_FISHING_FULL',
  'T7_JOURNAL_TROPHY_FISHING_EMPTY',
  'T7_JOURNAL_TROPHY_FISHING_FULL',
  'T8_JOURNAL_TROPHY_FISHING_EMPTY',
  'T8_JOURNAL_TROPHY_FISHING_FULL',
  'T2_JOURNAL_FISHING_EMPTY',
  'T2_JOURNAL_FISHING_FULL',
  'T3_JOURNAL_FISHING_EMPTY',
  'T3_JOURNAL_FISHING_FULL',
  'T4_JOURNAL_FISHING_EMPTY',
  'T4_JOURNAL_FISHING_FULL',
  'T5_JOURNAL_FISHING_EMPTY',
  'T5_JOURNAL_FISHING_FULL',
  'T6_JOURNAL_FISHING_EMPTY',
  'T6_JOURNAL_FISHING_FULL',
  'T7_JOURNAL_FISHING_EMPTY',
  'T7_JOURNAL_FISHING_FULL',
  'T8_JOURNAL_FISHING_EMPTY',
  'T8_JOURNAL_FISHING_FULL',
];
